import { productService } from '@/services'
import { sharedHelper } from '@/utils'
import { SHARED } from '@/constants'
import { PRODUCT_STATUS } from '@/constants/enums'
import { M_ORDER_SET_CART_ITEM_PRICES } from './order.module'
const MODULE_NAME = 'product/'

const A_PRODUCT_PRODUCTS = 'productProducts'
const A_PRODUCT_PRODUCT_BY_GROUP = 'productProductByGroup'
const A_TOTAL_CART_PRICE = 'productTotalCartPrice'

export const PRODUCT_PRODUCTS = MODULE_NAME + A_PRODUCT_PRODUCTS
export const PRODUCT_PRODUCT_BY_GROUP = MODULE_NAME + A_PRODUCT_PRODUCT_BY_GROUP
export const PRODUCT_TOTAL_CART_PRICE = MODULE_NAME + A_TOTAL_CART_PRICE

const M_PRODUCT_PRODUCTS = 'setProductProducts'
const M_PRODUCT_PRODUCT = 'setProductProduct'
const M_PRODUCT_RESET_PRODUCT_STORE = 'resetProductStore'
const M_PRODUCT_TOTAL_CART_PRICE = 'calculateTotalCartPrice'

const state = {
  products: {
    data: null,
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      lastPage: 0,
      tableRowsCount: 0
    }
  },
  product: {},
  totalCartPrice: 0,
  cartProductVariantInfo: []
}

const getters = {}

const actions = {
  [A_PRODUCT_PRODUCTS]({ commit, dispatch }, { filter }) {
    commit(M_PRODUCT_RESET_PRODUCT_STORE)
    sharedHelper.loading(dispatch)
    productService
      .getProducts(filter)
      .then((data) => {
        commit(M_PRODUCT_PRODUCTS, { data })
      })
      .catch((err) => {
        commit(M_PRODUCT_PRODUCTS, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_PRODUCT_PRODUCT_BY_GROUP]({ commit, dispatch }, { filter }) {
    sharedHelper.loading(dispatch)
    productService
      .getProductByGroup(filter)
      .then((data) => {
        commit(M_PRODUCT_PRODUCT, { data, filter })
      })
      .catch((err) => {
        commit(M_PRODUCT_PRODUCT, { err, filter })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_TOTAL_CART_PRICE]({ state, commit, dispatch }, { products }) {
    // recalculate from cached prices if they already exist to save on api calls
    const cachedSkus = state.cartProductVariantInfo.flatMap((prod) => prod.variants).map((v) => v.display_sku)
    if (products.every((p) => cachedSkus.includes(p.productDisplaySku)) && state.cartProductVariantInfo.length > 0) {
      commit(M_PRODUCT_TOTAL_CART_PRICE, { cartProductVariantInfo: state.cartProductVariantInfo, products })
      commit(`order/${M_ORDER_SET_CART_ITEM_PRICES}`, { cartProductVariantInfo: state.cartProductVariantInfo }, { root: true })
      return
    }

    sharedHelper.loading(dispatch)
    const filter = {
      displaySkus: products.map((p) => p.productDisplaySku)
    }
    return productService
      .getProducts(filter)
      .then((data) => {
        commit(M_PRODUCT_TOTAL_CART_PRICE, { data, products })
        commit(`order/${M_ORDER_SET_CART_ITEM_PRICES}`, { cartProductVariantInfo: state.cartProductVariantInfo }, { root: true })
      })
      .catch((err) => {
        commit(M_PRODUCT_TOTAL_CART_PRICE, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  }
}

const mutations = {
  [M_PRODUCT_PRODUCTS](state, { data }) {
    let pagination = data.pagination
    state.products.pagination = {
      pageNumber: pagination.current_page,
      pageSize: Number(pagination.per_page),
      lastPage: pagination.last_page,
      tableRowsCount: pagination.total
    }
    state.products.data = data.data.map((o) => {
      return {
        group: o.group,
        name: o.name,
        model: o.model,
        description: o.description,
        status: o.status,
        lowestSellingPrice: o.lowest_selling_price,
        highestSellingPrice: o.highest_selling_price,
        lowestDiscountedPrice: o.lowest_discounted_price,
        highestDiscountedPrice: o.highest_discounted_price,
        highestDiscountedRate: Math.floor(o.highest_discounted_rate * 100),
        gallery: o.gallery.map((o) => {
          return `${SHARED.MEDIA_SERVER_URL}/${o}`
        }),
        brand: o.brand,
        tax: o.tax,
        types: o.types,
        categories: o.categories
      }
    })
  },
  [M_PRODUCT_PRODUCT](state, { data, filter }) {
    let d = data.data

    let flattedVariantOptions = []
    let filteredRawVariant = d.variants.filter((x) => {
      if (filter.preOrder) {
        return x.status == PRODUCT_STATUS.NEW_RELEASE
      }
      return true
    })

    filteredRawVariant.forEach((v) => {
      v.options.forEach((x) => {
        flattedVariantOptions.push({
          name: x.name,
          text: x.value,
          value: x.value,
          code: `${x.name};${x.value}`,
          sku: v.display_sku,
          status: x.status
        })
      })
    })

    const allAvailableSku = d.variants.map((v) => v.display_sku)

    const variants = filteredRawVariant.map((variant) => ({
      sku: variant.display_sku,
      option: variant.options,
      marketPrice: variant.market_price,
      discountedPrice: variant.discounted_price,
      discountRate: Math.floor(variant.discount_rate * 100),
      sellingPrice: variant.selling_price,
      status: variant.status
    }))

    /* create and populate variantOptions for dropdown selections in the product page
      {
        key: the category name of the variant (string, e.g. 'Color')
        data: the list of options for that category (dropdown option[], e.g. {text: 'Red', value: 'Red'}[])
      }[]
    */
    let variantOptions = []
    const uniqueVariantCategories = [...new Set(flattedVariantOptions.map((item) => item.name))]
    uniqueVariantCategories.forEach((key) => {
      variantOptions.push({
        key: key,
        data: []
      })

      let availableOption = flattedVariantOptions.filter((x) => x.name == key)
      let uniqueAvailableOption = [...new Set(availableOption.map((item) => item.value))]

      uniqueAvailableOption.forEach((keyOption) => {
        let skuForKey = flattedVariantOptions.filter((x) => x.name == key && x.value == keyOption).map((e) => e.sku)

        variantOptions
          .find((x) => x.key == key)
          .data.push({
            text: keyOption,
            value: keyOption,
            sku: skuForKey,
            disabled: false
          })
      })
    })

    let lowestPrice = 0
    let highestPrice = 0
    let lowestDiscountedPrice = 0
    let highestDiscountedPrice = 0
    let lowestDiscountedRate = 0
    let highestDiscountedRate = 0
    let lowestSellingPrice = 0
    let highestSellingPrice = 0

    lowestPrice = Math.min.apply(
      null,
      filteredRawVariant.map(function (item) {
        return item.discounted_price
      })
    )

    highestPrice = Math.max.apply(
      null,
      filteredRawVariant.map(function (item) {
        return item.discounted_price
      })
    )

    lowestDiscountedPrice = Math.min.apply(
      null,
      filteredRawVariant.map(function (item) {
        return item.discounted_price
      })
    )

    highestDiscountedPrice = Math.max.apply(
      null,
      filteredRawVariant.map(function (item) {
        return item.discounted_price
      })
    )

    lowestDiscountedRate = Math.min.apply(
      null,
      filteredRawVariant.map(function (item) {
        return item.discount_rate
      })
    )

    highestDiscountedRate = Math.max.apply(
      null,
      filteredRawVariant.map(function (item) {
        return item.discount_rate
      })
    )
    lowestSellingPrice = Math.min.apply(
      null,
      filteredRawVariant.map(function (item) {
        return item.market_price
      })
    )

    highestSellingPrice = Math.max.apply(
      null,
      filteredRawVariant.map(function (item) {
        return item.market_price
      })
    )

    state.product = {
      group: d.group,
      name: d.name,
      model: d.model,
      description: d.description,
      gallery: d.gallery.map((o) => {
        return `${SHARED.MEDIA_SERVER_URL}/${o}`
      }),
      lowestPrice: lowestPrice,
      highestPrice: highestPrice,
      lowestDiscountedPrice: lowestDiscountedPrice,
      highestDiscountedPrice: highestDiscountedPrice,
      lowestSellingPrice: lowestSellingPrice,
      highestSellingPrice: highestSellingPrice,
      lowestDiscountedRate: Math.floor(lowestDiscountedRate * 100),
      highestDiscountedRate: Math.floor(highestDiscountedRate * 100),
      variants: variants, // raw variants
      variantOptions: variantOptions, // list of variant categories and their dropdown options
      allAvailableSku: allAvailableSku,
      optionData: flattedVariantOptions,
      types: d.types,
      insuranceType: d.types.find((x) => ['phones', 'tablets'].includes(x.name.toLowerCase()))?.name || ''
    }
  },
  [M_PRODUCT_RESET_PRODUCT_STORE](state) {
    state.product = {}
  },
  [M_PRODUCT_TOTAL_CART_PRICE](state, { cartProductVariantInfo, data, products }) {
    let flattenedProductVariants
    if (cartProductVariantInfo) {
      flattenedProductVariants = cartProductVariantInfo.flatMap((prod) => prod.variants)
    } else {
      state.cartProductVariantInfo = data?.data
      flattenedProductVariants = data?.data.flatMap((prod) => prod.variants)
    }
    if (products?.length > 0) {
      let sum = 0
      products.forEach((product) => {
        const unitPrice = flattenedProductVariants.find((variant) => variant.display_sku === product.productDisplaySku)?.discounted_price ?? 0
        sum += unitPrice * product.quantity
      })
      state.totalCartPrice = sum
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
