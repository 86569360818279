<template>
  <v-dialog persistent :value="dialogShow" class="rounded-lg" @keydown.esc="closeAction" :max-width="maxWidth">
    <v-card class="dialog-card" color="light_gray">
      <v-row no-gutters class="dialog-header p-relative py-3">
        <label v-if="title" class="title pa-3 ma-0 text-center unbox_primary--text text-capitalize d-block full-width font-weight-bold">{{ title }}</label>
        <slot name="header"></slot>
        <v-icon class="dialog-close-icon stroke--unbox_black" v-if="closeAction != null" v-text="'$xIcon'" @click="closeAction"></v-icon>
      </v-row>
      <v-row no-gutters class="d-block dialog-row pb-6 px-3 px-lg-6" :class="customClass" v-if="dialogShow">
        <slot name="body"></slot>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'appDialog',
  props: {
    closeAction: {
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    maxWidth: {
      type: Number,
      required: false,
      default: undefined
    },
    dialogShow: {
      type: Boolean,
      required: true,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-close-icon {
  position: absolute !important;
  top: 20px;
  right: 10px;
  font-size: 30px !important;
  cursor: pointer;
  transform: translateY(-50%);
}
.title {
  width: 90%;
  margin: auto !important;
}
</style>
