import { REGEX } from '@/constants/constants'
import { localeHelper } from '@/utils'

/* Rules */
export function nameRules() {
  return [requiredField(), noMultipleSpace(), nameFormat(), minMaxLength(2, 50)]
}

export function passwordRules() {
  return [
    requiredField(),
    passwordGotDigitFormat(),
    passwordMinimumLengthFormat(),
    passwordMaximumLengthFormat(),
    passwordGotUpperCaseFormat(),
    passwordGotLowerCaseFormat(),
    passwordGotSpecialCharacterFormat(),
    passwordFormat(),
    minMaxLength(8, 20)
  ]
}

export function confirmPasswordRules(targetValue, value) {
  return [
    requiredField(),
    passwordGotDigitFormat(),
    passwordMinimumLengthFormat(),
    passwordMaximumLengthFormat(),
    passwordGotUpperCaseFormat(),
    passwordGotLowerCaseFormat(),
    passwordGotSpecialCharacterFormat(),
    passwordFormat(),
    comparePasswordValue(targetValue, value)
  ]
}

export function emailRules() {
  return [requiredField(), noMultipleSpace(), emailFormat(), minMaxLength(1, 50)]
}

export function requiredRules() {
  return [requiredField()]
}

export function mobileNumberRules() {
  return [requiredField(), noMultipleSpace(), mobileNumberFormat()]
}

export function malaysianIdentityNumberRules() {
  return [requiredField(), noMultipleSpace(), malaysianIdentityNumberFormat()]
}

export function addressRules() {
  return [requiredField(), noMultipleSpace(), minMaxLength(5, 100)]
}

export function addressBlockRules() {
  return [requiredField(), noMultipleSpace(), minMaxLength(1, 100)]
}

export function postcodeRules() {
  return [requiredField(), integerFormat(), minMaxLength(5, 5)]
}

export function dropDownListRules() {
  return [requiredDropDownValue()]
}

export function phoneRules() {
  return [requiredField(), noMultipleSpace(), phoneFormat()]
}

export function tacRules() {
  return [requiredField(), integerFormat(), minMaxLength(6, 6)]
}

export function financingAmountRules(min, max) {
  return [requiredField(), integerFormat(), minimumFinancingAmountFormat(min), maximumFinancingAmountFormat(max)]
}

export function serviceRules(isRequired, regex) {
  const validators = [regexField(regex)]
  if (isRequired) validators.unshift(requiredField())
  return validators
}

/* Validation */
function requiredField() {
  return (v) => !!v || localeHelper.getMessage('validationMessage.requiredField')
}

function nameFormat() {
  return (v) => (v && REGEX.NAME.test(v)) || localeHelper.getMessage('validationMessage.invalidNameFormat')
}

function noMultipleSpace() {
  return (v) => (v && REGEX.MULTIPLE_SPACE.test(v)) || localeHelper.getMessage('validationMessage.noMultipleSpace')
}

function minMaxLength(min, max) {
  if (min == 0 && max == 0) {
    return true
  } else {
    return (v) => (v && v.length >= min && v.length <= max) || localeHelper.getMessage('validationMessage.exceedMinMaxLength', [min, max])
  }
}

function passwordMinimumLengthFormat() {
  return (v) => (v && v.length >= 8) || localeHelper.getMessage('validationMessage.passwordRulesMinimumLength')
}

function passwordMaximumLengthFormat() {
  return (v) => (v && v.length <= 20) || localeHelper.getMessage('validationMessage.passwordRulesMaximumLength')
}

function passwordGotUpperCaseFormat() {
  return (v) => (v && REGEX.ATLEAST_ONE_UPPERCASE_LETTER.test(v)) || localeHelper.getMessage('validationMessage.passwordRulesUppercaseLetter')
}

function passwordGotLowerCaseFormat() {
  return (v) => (v && REGEX.ATLEAST_ONE_LOWERCASE_LETTER.test(v)) || localeHelper.getMessage('validationMessage.passwordRulesLowercaseLetter')
}

function passwordGotDigitFormat() {
  return (v) => (v && REGEX.ATLEAST_ONE_DIGIT.test(v)) || localeHelper.getMessage('validationMessage.passwordRulesDigit')
}

function passwordGotSpecialCharacterFormat() {
  return (v) => (v && REGEX.ATLEAST_ONE_SPECIAL_CHARACTER.test(v)) || localeHelper.getMessage('validationMessage.passwordRulesSpecialCharacter')
}

function passwordFormat() {
  return (v) => (v && REGEX.PASSWORD.test(v)) || localeHelper.getMessage('validationMessage.invalidPasswordFormat')
}

function emailFormat() {
  return (v) => (v && REGEX.EMAIL.test(v)) || localeHelper.getMessage('validationMessage.invalidEmailFormat')
}

function mobileNumberFormat() {
  return (v) => (v && REGEX.MOBILE_NUMBER.test(v)) || localeHelper.getMessage(`validationMessage.invalidMobileNumberFormat`)
}

function phoneFormat() {
  return (v) => (v && REGEX.PHONE.test(v)) || localeHelper.getMessage(`validationMessage.invalidMobileNumberFormat`)
}

function comparePasswordValue(targetValue, value) {
  return () => targetValue === value || localeHelper.getMessage('validationMessage.confirmPasswordNotMatch')
}

function malaysianIdentityNumberFormat() {
  return (v) => (v && REGEX.MALAYSIAN_IDENTITY_NUMBER.test(v) && malaysianIdentityNumberDateChecking(v)) || localeHelper.getMessage(`validationMessage.invalidIdentityNumberFormat`)
}

function malaysianIdentityNumberDateChecking(identityNumber) {
  if (identityNumber.match(REGEX.MALAYSIAN_IDENTITY_NUMBER)) {
    let year = RegExp.$2
    let month = RegExp.$3
    let day = RegExp.$4
    let now = new Date().getFullYear().toString()
    let decade = now.substr(0, 2)

    if (now.substr(2, 2) > year) {
      year = parseInt(decade.concat(year.toString()), 10)
    } else {
      year = '19' + year
    }

    let d = new Date(year, month - 1, day)
    if (d.getFullYear() == year && d.getMonth() == month - 1 && d.getDate() == day) {
      return true
    }
    return false
  }
}

function integerFormat() {
  return (v) => (v && REGEX.NUMBER.test(v)) || localeHelper.getMessage('validationMessage.integerFormat')
}

function requiredDropDownValue() {
  return (v) => (!!v && v != '') || localeHelper.getMessage('validationMessage.requiredDropDownField')
}

function minimumFinancingAmountFormat(min) {
  return (v) => v >= min || localeHelper.getMessage('validationMessage.minimumFinancingAmount', ['RM ' + min])
}

function maximumFinancingAmountFormat(max) {
  return (v) => v <= max || localeHelper.getMessage('validationMessage.maximumFinancingAmount', ['RM ' + max])
}

function regexField(regex) {
  return (v) => (v && new RegExp(regex.replaceAll('/', '')).test(v)) || localeHelper.getMessage(`validationMessage.invalidFormat`)
}
