export const SHARED = Object.freeze({
  PREORDER_TRANSACTION_TYPE_UUID: process.env.VUE_APP_PREORDER_TRANSACION_TYPE_UUID,
  NORMALORDER_TRANSACTION_TYPE_UUID: process.env.VUE_APP_NORMALORDER_TRANSACION_TYPE_UUID,
  GA_TRACKING_CODE: process.env.VUE_APP_GOOGLE_ANALYTICS,
  LAYOUT: 'layout',
  PLATFORM: 'Web',
  CART_PLATFORM: 'ecommerce',
  PRODUCT_GROUP_PREFIX: 'grp',
  PRODUCT_TYPE_PREFIX: 'typ',
  PRODUCT_BRAND_PREFIX: 'brands',
  INSTALLMENT_PERIOD: 24,
  API_KEY: process.env.VUE_APP_API_KEY,
  CREATE_PASSWORD: 'CREATE_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD_FIRST_TIME_LOGIN: 'first_time_login',
  RESET_PASSWORD_FORGOT_PASSWORD: 'forgot_password',
  PRODUCT_FEATURED_COLLECTION_UUID: process.env.VUE_APP_PRODUCT_FEATURE_COLLECTION_UUID,
  MEDIA_SERVER_URL: process.env.VUE_APP_MEDIA_SERVER_BASE_URL,
  FILTERABLE_BRAND: ['APL', 'XMI', 'VVO', 'OPP', 'SAM', 'HWI', 'HNR', 'DJI', 'PEN', 'WED', 'HAR', 'INF', 'NES'],
  PAYMENT_TYPE_SORTING: ['CreditCard', 'BuyNowPayLater', 'FPX', 'eWallet', 'OfflineBanking']
})

export const EXTERNAL_PRODUCT =
  process.env.NODE_ENV == 'production'
    ? Object.freeze({
        INSURANCE: {
          PHONES: {
            title: '2 year Device Screen Crack Replacement',
            tier: [
              {
                display_sku: 'UBX-ZM-hibh88',
                sku: 'ASCCON-UBX-ZM-LOjCys',
                unit_price: 99,
                tierLevel: 1,
                name: '2 year Device Screen Crack Replacement Tier 1',
                minCoverPrice: 0.01,
                maxCoverPrice: 1000
              },
              {
                display_sku: 'UBX-ZM-l7M6mG',
                sku: 'ASCCON-UBX-ZM-nw7jTA',
                unit_price: 199,
                tierLevel: 2,
                name: '2 year Device Screen Crack Replacement Tier 2',
                minCoverPrice: 1000.01,
                maxCoverPrice: 1500
              },
              {
                display_sku: 'UBX-ZM-hibh88',
                sku: 'ASCCON-UBX-ZM-l7M6mG',
                unit_price: 299,
                tierLevel: 3,
                name: '2 year Device Screen Crack Replacement Tier 3',
                minCoverPrice: 1500.01,
                maxCoverPrice: 2000
              },
              {
                display_sku: 'UBX-ZM-5iOrjs',
                sku: 'ASCCON-UBX-ZM-Zrq8Wk',
                unit_price: 399,
                tierLevel: 4,
                name: '2 year Device Screen Crack Replacement Tier 4',
                minCoverPrice: 2000.01,
                maxCoverPrice: 4000
              },
              {
                display_sku: 'UBX-ZM-7EppIW',
                sku: 'ASCCON-UBX-ZM-Gi4KeA',
                unit_price: 699,
                tierLevel: 5,
                name: '2 year Device Screen Crack Replacement Tier 5',
                minCoverPrice: 4000.01,
                maxCoverPrice: 6000
              },
              {
                display_sku: 'UBX-ZM-HYVOZU',
                sku: 'ASCCON-UBX-ZM-ygLBQy',
                unit_price: 999,
                tierLevel: 6,
                name: '2 year Device Screen Crack Replacement Tier 6',
                minCoverPrice: 6000.01,
                maxCoverPrice: 8000
              }
            ]
          },
          TABLETS: {
            title: '2 year Device Screen Crack Replacement',
            tier: [
              {
                display_sku: 'UBX-ZN-qXHlQS',
                sku: 'ASCCON-UBX-ZN-MGWPRv',
                unit_price: 99,
                tierLevel: 1,
                name: '2 year Device Screen Crack Replacement Tier 1',
                minCoverPrice: 0,
                maxCoverPrice: 1000
              },
              {
                display_sku: 'UBX-ZN-YK7Y43',
                sku: 'ASCCON-UBX-ZN-KZb0hh',
                unit_price: 199,
                tierLevel: 2,
                name: '2 year Device Screen Crack Replacement Tier 2',
                minCoverPrice: 1000.01,
                maxCoverPrice: 1500
              },
              {
                display_sku: 'UBX-ZN-WhUwmo',
                sku: 'ASCCON-UBX-ZN-lqlAoP',
                unit_price: 299,
                tierLevel: 3,
                name: '2 year Device Screen Crack Replacement Tier 3',
                minCoverPrice: 1500.01,
                maxCoverPrice: 2000
              },
              {
                display_sku: 'UBX-ZN-lOFywi',
                sku: 'ASCCON-UBX-ZN-wk8jZ1',
                unit_price: 399,
                tierLevel: 4,
                name: '2 year Device Screen Crack Replacement Tier 4',
                minCoverPrice: 2000.01,
                maxCoverPrice: 4000
              },
              {
                display_sku: 'UBX-ZN-ddg9Il',
                sku: 'ASCCON-UBX-ZN-9GFaOl',
                unit_price: 699,
                tierLevel: 5,
                name: '2 year Device Screen Crack Replacement Tier 5',
                minCoverPrice: 4000.01,
                maxCoverPrice: 6000
              },
              {
                display_sku: 'UBX-ZN-ihg1D7',
                sku: 'ASCCON-UBX-ZN-ug690R',
                unit_price: 999,
                tierLevel: 6,
                name: '2 year Device Screen Crack Replacement Tier 6',
                minCoverPrice: 6000.01,
                maxCoverPrice: 8000
              }
            ]
          }
        }
      })
    : process.env.NODE_ENV == 'staging'
    ? Object.freeze({
        INSURANCE: {
          PHONES: {
            title: '2 year Device Screen Crack Replacement',
            tier: [
              {
                display_sku: 'UNB-IK-Ks45l9',
                sku: 'ASC-UNB-IK-MLTbvP',
                unit_price: 99,
                tierLevel: 1,
                name: '2 year Device Screen Crack Replacement Tier 1',
                minCoverPrice: 0,
                maxCoverPrice: 1000
              },
              {
                display_sku: 'UNB-IK-X0EtSO',
                sku: 'ASC-UNB-IK-2z9yUu',
                unit_price: 199,
                tierLevel: 2,
                name: '2 year Device Screen Crack Replacement Tier 2',
                minCoverPrice: 1000.01,
                maxCoverPrice: 1500
              },
              {
                display_sku: 'UNB-IK-CMgJoM',
                sku: 'ASC-UNB-IK-7CrXeI',
                unit_price: 299,
                tierLevel: 3,
                name: '2 year Device Screen Crack Replacement Tier 3',
                minCoverPrice: 1500.01,
                maxCoverPrice: 2000
              },
              {
                display_sku: 'UNB-IK-2S8Juy',
                sku: 'ASC-UNB-IK-ti7ReQ',
                unit_price: 399,
                tierLevel: 4,
                name: '2 year Device Screen Crack Replacement Tier 4',
                minCoverPrice: 2000.01,
                maxCoverPrice: 4000
              },
              {
                display_sku: 'UNB-IK-FJZkQt',
                sku: 'ASC-UNB-IK-vrIYic',
                unit_price: 699,
                tierLevel: 5,
                name: '2 year Device Screen Crack Replacement Tier 5',
                minCoverPrice: 4000.01,
                maxCoverPrice: 6000
              },
              {
                display_sku: 'UNB-IK-XKnati',
                sku: 'ASC-UNB-IK-gjhqGQ',
                unit_price: 999,
                tierLevel: 6,
                name: '2 year Device Screen Crack Replacement Tier 6',
                minCoverPrice: 6000.01,
                maxCoverPrice: 8000
              }
            ]
          },
          TABLETS: {
            title: '2 year Device Screen Crack Replacement',
            tier: [
              {
                display_sku: 'UBX-ZN-qXHlQS',
                sku: 'ASC-UNB-IL-3RwdM9',
                unit_price: 99,
                tierLevel: 1,
                name: '2 year Device Screen Crack Replacement Tier 1',
                minCoverPrice: 0,
                maxCoverPrice: 1000
              },
              {
                display_sku: 'UBX-ZN-exwlXC',
                sku: 'ASC-UNB-IL-u3izaH',
                unit_price: 199,
                tierLevel: 2,
                name: '2 year Device Screen Crack Replacement Tier 2',
                minCoverPrice: 1000.01,
                maxCoverPrice: 1500
              },
              {
                display_sku: 'UBX-ZN-1Ex41W',
                sku: 'ASC-UNB-IL-sZZ85g',
                unit_price: 299,
                tierLevel: 3,
                name: '2 year Device Screen Crack Replacement Tier 3',
                minCoverPrice: 1500.01,
                maxCoverPrice: 2000
              },
              {
                display_sku: 'UBX-ZN-CEMiOD',
                sku: 'ASC-UNB-IL-6mwltW',
                unit_price: 399,
                tierLevel: 4,
                name: '2 year Device Screen Crack Replacement Tier 4',
                minCoverPrice: 2000.01,
                maxCoverPrice: 4000
              },
              {
                display_sku: 'UBX-ZN-duGL2W',
                sku: 'ASC-UNB-IL-0DiL4K',
                unit_price: 699,
                tierLevel: 5,
                name: '2 year Device Screen Crack Replacement Tier 5',
                minCoverPrice: 4000.01,
                maxCoverPrice: 6000
              },
              {
                display_sku: 'UBX-ZN-p8nTYx',
                sku: 'ASC-UNB-IL-ON4ZrI',
                unit_price: 999,
                tierLevel: 6,
                name: '2 year Device Screen Crack Replacement Tier 6',
                minCoverPrice: 6000.01,
                maxCoverPrice: 8000
              }
            ]
          }
        }
      })
    : Object.freeze({
        INSURANCE: {
          PHONES: {
            title: '2 year Device Screen Crack Replacement',
            tier: [
              {
                display_sku: 'UNB-NN-JoalV3',
                sku: 'ASC-UNB-NN-yl5y19',
                unit_price: 99,
                tierLevel: 1,
                name: '2 year Device Screen Crack Replacement Tier 1',
                minCoverPrice: 0,
                maxCoverPrice: 1000
              },
              {
                display_sku: 'UNB-NN-YlB0Yg',
                sku: 'ASC-UNB-NN-oy2MeZ',
                unit_price: 199,
                tierLevel: 2,
                name: '2 year Device Screen Crack Replacement Tier 2',
                minCoverPrice: 1000.01,
                maxCoverPrice: 1500
              },
              {
                display_sku: 'UNB-NN-MoIeNc',
                sku: 'ASC-UNB-NN-atElVL',
                unit_price: 299,
                tierLevel: 3,
                name: '2 year Device Screen Crack Replacement Tier 3',
                minCoverPrice: 1500.01,
                maxCoverPrice: 2000
              },
              {
                display_sku: 'UNB-NN-uBNfbE',
                sku: 'ASC-UNB-NN-x6i2d8',
                unit_price: 399,
                tierLevel: 4,
                name: '2 year Device Screen Crack Replacement Tier 4',
                minCoverPrice: 2000.01,
                maxCoverPrice: 4000
              },
              {
                display_sku: 'UNB-NN-tUl1FE',
                sku: 'ASC-UNB-NN-NuYLga',
                unit_price: 699,
                tierLevel: 5,
                name: '2 year Device Screen Crack Replacement Tier 5',
                minCoverPrice: 4000.01,
                maxCoverPrice: 6000
              },
              {
                display_sku: 'UNB-NN-3VKXlY',
                sku: 'ASC-UNB-NN-fMamG9',
                unit_price: 999,
                tierLevel: 6,
                name: '2 year Device Screen Crack Replacement Tier 6',
                minCoverPrice: 6000.01,
                maxCoverPrice: 8000
              }
            ]
          },
          TABLETS: {
            title: '2 year Device Screen Crack Replacement',
            tier: [
              {
                display_sku: 'UNB-NO-C7qZvf',
                sku: 'ASC-UNB-NO-2rnY3g',
                unit_price: 99,
                tierLevel: 1,
                name: '2 year Device Screen Crack Replacement Tier 1',
                minCoverPrice: 0,
                maxCoverPrice: 1000
              },
              {
                display_sku: 'UNB-NO-KzxfLa',
                sku: 'ASC-UNB-NO-l1ELXt',
                unit_price: 199,
                tierLevel: 2,
                name: '2 year Device Screen Crack Replacement Tier 2',
                minCoverPrice: 1000.01,
                maxCoverPrice: 1500
              },
              {
                display_sku: 'UNB-NO-PjoJGN',
                sku: 'ASC-UNB-NO-cuSQ2G',
                unit_price: 299,
                tierLevel: 3,
                name: '2 year Device Screen Crack Replacement Tier 3',
                minCoverPrice: 1500.01,
                maxCoverPrice: 2000
              },
              {
                display_sku: 'UNB-NO-mrm9xs',
                sku: 'ASC-UNB-NO-5eWlUK',
                unit_price: 399,
                tierLevel: 4,
                name: '2 year Device Screen Crack Replacement Tier 4',
                minCoverPrice: 2000.01,
                maxCoverPrice: 4000
              },
              {
                display_sku: 'UNB-NO-7uGXm8',
                sku: 'ASC-UNB-NO-KHDR4K',
                unit_price: 699,
                tierLevel: 5,
                name: '2 year Device Screen Crack Replacement Tier 5',
                minCoverPrice: 4000.01,
                maxCoverPrice: 6000
              },
              {
                display_sku: 'UNB-NO-I55sbn',
                sku: 'ASC-UNB-NO-XiXDBk',
                unit_price: 999,
                tierLevel: 6,
                name: '2 year Device Screen Crack Replacement Tier 6',
                minCoverPrice: 6000.01,
                maxCoverPrice: 8000
              }
            ]
          }
        }
      })

export const MEDIUM = Object.freeze({
  EMAIL: 'email'
})

export const PRODUCT_TYPE = Object.freeze({
  PRODUCT: 'Product',
  SERVICE: 'Service'
})

export const DELIVERY_METHOD = Object.freeze({
  SELF_PICKUP: process.env.VUE_APP_ORDER_DELIVERY_METHOD_PICKUP.toLowerCase(),
  DELIVERY: process.env.VUE_APP_ORDER_DELIVERY_METHOD_DELIVERY.toLowerCase()
})

export const SESSION = Object.freeze({
  CUSTOMER_UUID: 'TQRXZHOENV',
  JWT_TOKEN: 'authToken',
  TOKEN_EXPIRY_TIME: 'authTokenExpiryTime',
  TOKEN_REFRESH_TIME: 'authTokenRefreshTime',
  USERNAME: 'username',
  EMAIL: 'email',
  LAST_LOGIN_TIME: 'lastLoginDateTime',
  LAST_ACTIVITY_TIME: 'lastActivityDateTime',
  SHOP_SEARCH: 'shopSearchCriteria',
  CART: 'shoppingCart',
  CHECKOUT_CART: 'checkoutCart',
  BUYNOW_CART: 'buyNowCart',
  PAYMENT_TRANSACTION_INFO: 'paymentTransactionInfo'
})

export const API = Object.freeze({
  BASE_URL: process.env.VUE_APP_API_BASE_URL,
  MAINGUN_API_KEY: process.env.VUE_APP_MAILGUN_API_KEY
})

export const CACHE_KEY = Object.freeze({
  PRODUCT_TYPE: 'productType',
  PRODUCT_TYPE_TREEVIEW: 'productTypeTreeview'
})

export const KEY = Object.freeze({
  RMBME_SECRET_KEY: process.env.VUE_APP_RMBME_ENCRYPT_KEY,
  UN_KEY: process.env.VUE_APP_UN_KEY,
  PW_KEY: process.env.VUE_APP_PW_KEY
})

export const REGEX = Object.freeze({
  bonusCode: /^[a-zA-Z0-9]+$/,
  username: /^[a-zA-Z0-9]+$/,

  mobileNumberChina: /^(?=\d{11}$)^1(?:3\d|4[57]|5[^4\D]|66|7[^249\D]|8\d|9[89])\d{8}$/,

  referralCode: /^[a-zA-Z0-9]+$/,

  invalidCharacters: /^[^;:：；<>《》=*]+$/,

  wechat: /^[a-zA-Z0-9_-]+$/,
  integer: /^[0-9]+(\.[0-9]{0,0})?$/,
  decimalTwo: /^-?[0-9]+(\.[0-9]{0,2})?$/,
  decimalFour: /^-?[0-9]+(\.[0-9]{0,4})?$/,
  bankNumberSixDigit: /^[0-9]{6}$/,

  ADDRESS: /^[a-zA-Z\u4e00-\u9eff-\u0E00-\u0E7F./_]+( [a-zA-Z\u4e00-\u9eff-\u0E00-\u0E7F./_]+)*$/,
  PASSWORD: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&])(?=.{8,20})/,
  NAME: /^[a-zA-Z\u4e00-\u9eff-\u0E00-\u0E7F./_]+( [a-zA-Z\u4e00-\u9eff-\u0E00-\u0E7F./_]+)*$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  MULTIPLE_SPACE: /^\S+(?: \S+)*$/,
  ATLEAST_ONE_LOWERCASE_LETTER: /(?=.*[a-z])/,
  ATLEAST_ONE_UPPERCASE_LETTER: /(?=.*[A-Z])/,
  ATLEAST_ONE_DIGIT: /(?=.*[0-9])/,
  ATLEAST_ONE_SPECIAL_CHARACTER: /(?=.*[!@#$%^&])/,
  MOBILE_NUMBER: /^(1)[0-46-9]*[0-9]{7,8}$/,
  PHONE: /^[0-9][0-46-9]*[0-9]{7,8}$/,
  MALAYSIAN_IDENTITY_NUMBER: /(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-([0-9]{2})-([0-9]{4})/,
  NUMBER: /^[\d]+$/
})

/* VALUE CANT BE CHANGE */
export const ACTION = Object.freeze({
  REGISTER: 1,
  LOGIN: 0
})

export const DATE = Object.freeze({
  DEFAULT_DATE: 'YYYY-MM-DD',
  DEFAULT_MONTH: 'YYYY-MM',
  SHORT: 'DD-MMM-YYYY',
  LONG: 'DD-MMM-YYYY hh:mm:ss A',
  TIME: 'HH:mm:ss',
  LAST_LOGIN_DATE: 'ddd, DD MMM YYYY',
  LAST_LOGIN_TIME: 'hh.mmA,',
  FULL_MONTH_DATE: 'DD MMMM YYYY',
  DEFAULT_TIMEZONE_START: 'T00:00:00+0800',
  DEFAULT_TIMEZONE_END: 'T23:59:59+0800'
})
