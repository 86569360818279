<template>
  <div class="d-inline-block full-width">
    <v-btn
      v-if="type == 'default'"
      class="full-width white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8 mb-2"
      :class="customClass"
      @click="action"
      :height="height"
      :max-width="maxWidth"
      color="unbox_black"
      elevation="0"
    >
      {{ title }}
    </v-btn>
    <v-btn
      v-if="type == 'secondary'"
      class="full-width unbox_black--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8 text-decoration-underline"
      :class="customClass"
      @click="action"
      :height="height"
      :max-width="maxWidth"
      elevation="0"
    >
      {{ title }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'appButton',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    action: {
      type: Function,
      required: true,
      default: () => ({})
    },
    customClass: {
      type: String,
      required: false,
      default: undefined
    },
    type: {
      type: String,
      required: true,
      default: 'default'
    },
    height: {
      type: String,
      required: false,
      default: '56px'
    },
    maxWidth: {
      type: String,
      required: false,
      default: '300px'
    }
  }
}
</script>
