/* Functions here is to handle everything about
 * message localization
 */
import i18n from '@/plugins/i18n'

export function getMessage(key, dynamicValue = null) {
  /* Pass in dynamicValue in Array for message like:
   * "Record created by {0} on {1}." >> $t('label.createdInfo', [createdBy, createdTime])
   * "Must be between {0} - {1} characters" >> localeHelper.getMessage('error.exceedMinMaxLength', [min, max])
   */
  return dynamicValue == null ? i18n.t(key) : i18n.t(key, dynamicValue)
}

export function getErrorMessage(code) {
  let errorCode = code.split('.').join('_')
  let errorMsg = ''

  errorMsg = i18n.t(`errorMessage.${errorCode}`)

  return errorMsg
}
