import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n'
import { SHARED } from '@/constants/constants'
import '@babel/polyfill'
import 'es6-promise/auto'
import '@/scss/app.scss'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/700.css'
/*Plugin*/
import ddlPlugin from '@/plugins/ddl.plugin'
import sharedPlugin from '@/plugins/shared.plugin'
import validationPlugin from '@/plugins/validation.plugin'
import VueMask from 'v-mask'
import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'
/*Component*/
import AppDialog from '@/components/dialog/dialog.vue'
import AppFormField from '@/components/field/formField.vue'
import AppDatePicker from '@/components/field/datePicker.vue'
import Layout from '@/components/layout/layout.vue'
import BlankLayout from '@/components/layout/blankLayout.vue'
import AppButton from '@/components/button.vue'
Vue.use(VueAnalytics, {
  id: SHARED.GA_TRACKING_CODE,
  router
})
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(ddlPlugin)
Vue.use(validationPlugin)
Vue.use(sharedPlugin)
Vue.use(VueMask)
Vue.use(VueMeta)
Vue.component('layout', Layout)
Vue.component('blank-layout', BlankLayout)
Vue.component('app-dialog', AppDialog)
Vue.component('app-form-field', AppFormField)
Vue.component('app-date-picker', AppDatePicker)
Vue.component('app-button', AppButton)

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
