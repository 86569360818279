<template>
  <div>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="value" transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <div class="text-body-2 font-weight-bold unbox_secondary--text mb-2">{{ label }}</div>
        <v-text-field :value="value" class="rounded-lg" :placeholder="placeholder" outlined background-color="white" readonly v-bind="attrs" v-on="on"></v-text-field>
      </template>
      <v-date-picker :value="value" @input="input"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    menu: false
  }),
  methods: {
    input(value) {
      this.menu = false
      this.$emit('input', value)
    }
  }
}
</script>
