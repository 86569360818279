import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

/* Custom icons */
import FacebookIcon from '@/assets/icons/facebook.vue'
import InstagramIcon from '@/assets/icons/instagram.vue'
import PinterestIcon from '@/assets/icons/pinterest.vue'
import TwitterIcon from '@/assets/icons/twitter.vue'
import PaymentMasterCardIcon from '@/assets/icons/payment_Mastercard.vue'
import PaymentVisaIcon from '@/assets/icons/payment_Visa.vue'
import PaymentSecurePaymentIcon from '@/assets/icons/payment_SecurePayment.vue'
import RatingStarIcon from '@/assets/icons/rating_Star.vue'
import RatingStarEmptyIcon from '@/assets/icons/rating_StarEmpty.vue'
import AddToCartBlackIcon from '@/assets/icons/addToCartBlack.vue'
import AddToCartRedIcon from '@/assets/icons/addToCartRed.vue'
import DeliveryTruckIcon from '@/assets/icons/deliveryTruck.vue'
import ShoppingBagIcon from '@/assets/icons/shoppingBag.vue'
import BuyNowPayLaterIcon from '@/assets/icons/buyNowPayLater.vue'
import PackageIcon from '@/assets/icons/package.vue'
import ArrowLeftIcon from '@/assets/icons/arrowLeft.vue'
import ArrowRightIcon from '@/assets/icons/arrowRight.vue'
import SearchIcon from '@/assets/icons/search.vue'
import WalletIcon from '@/assets/icons/wallet.vue'
import AccountIcon from '@/assets/icons/account.vue'
import LanguageIcon from '@/assets/icons/language.vue'
import ComparisonIcon from '@/assets/icons/comparison.vue'
import CartIcon from '@/assets/icons/cart.vue'
import XIcon from '@/assets/icons/x.vue'
import CheckIcon from '@/assets/icons/check.vue'
import UncheckIcon from '@/assets/icons/uncheck.vue'
import ChevronDownIcon from '@/assets/icons/chevronDown.vue'
import ChevronUpIcon from '@/assets/icons/chevronUp.vue'
import ChevronLeftIcon from '@/assets/icons/chevronLeft.vue'
import ChevronRightIcon from '@/assets/icons/chevronRight.vue'
import CategoryIcon from '@/assets/icons/category.vue'
import ErrorFaceIcon from '@/assets/icons/errorFace.vue'
import ListIcon from '@/assets/icons/list.vue'
import GridIcon from '@/assets/icons/grid.vue'
import HamburgerIcon from '@/assets/icons/hamburger.vue'
import PickupIcon from '@/assets/icons/pickup.vue'
import PlusCircleIcon from '@/assets/icons/plusCircle.vue'
import FilterIcon from '@/assets/icons/filter.vue'
import WhatappIcon from '@/assets/icons/whatapp.vue'
import LocationIcon from '@/assets/icons/location.vue'
import ExclamationMarkIcon from '@/assets/icons/exclamationMark.vue'
import PaymentMethodIcon from '@/assets/icons/paymentMethod.vue'
import BillingIcon from '@/assets/icons/billing.vue'
import DownloadIcon from '@/assets/icons/download.vue'
import CopyIcon from '@/assets/icons/copy.vue'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      facebookIcon: {
        component: FacebookIcon
      },
      instagramIcon: {
        component: InstagramIcon
      },
      pinterestIcon: {
        component: PinterestIcon
      },
      twitterIcon: {
        component: TwitterIcon
      },
      paymentVisaIcon: {
        component: PaymentVisaIcon
      },
      paymentMasterCardIcon: {
        component: PaymentMasterCardIcon
      },
      paymentSecurePaymentIcon: {
        component: PaymentSecurePaymentIcon
      },
      ratingStarIcon: {
        component: RatingStarIcon
      },
      ratingStarEmptyIcon: {
        component: RatingStarEmptyIcon
      },
      addToCartBlackIcon: {
        component: AddToCartBlackIcon
      },
      addToCartRedIcon: {
        component: AddToCartRedIcon
      },
      deliveryTruckIcon: {
        component: DeliveryTruckIcon
      },
      shoppingBagIcon: {
        component: ShoppingBagIcon
      },
      buyNowPayLaterIcon: {
        component: BuyNowPayLaterIcon
      },
      packageIcon: {
        component: PackageIcon
      },
      arrowLeftIcon: {
        component: ArrowLeftIcon
      },
      arrowRightIcon: {
        component: ArrowRightIcon
      },
      searchIcon: {
        component: SearchIcon
      },
      walletIcon: {
        component: WalletIcon
      },
      accountIcon: {
        component: AccountIcon
      },
      languageIcon: {
        component: LanguageIcon
      },
      comparisonIcon: {
        component: ComparisonIcon
      },
      cartIcon: {
        component: CartIcon
      },
      xIcon: {
        component: XIcon
      },
      checkIcon: {
        component: CheckIcon
      },
      uncheckIcon: {
        component: UncheckIcon
      },
      chevronDownIcon: {
        component: ChevronDownIcon
      },
      chevronUpIcon: {
        component: ChevronUpIcon
      },
      chevronLeftIcon: {
        component: ChevronLeftIcon
      },
      chevronRightIcon: {
        component: ChevronRightIcon
      },
      categoryIcon: {
        component: CategoryIcon
      },
      errorFaceIcon: {
        component: ErrorFaceIcon
      },
      listIcon: {
        component: ListIcon
      },
      gridIcon: {
        component: GridIcon
      },
      hamburgerIcon: {
        component: HamburgerIcon
      },
      pickupIcon: {
        component: PickupIcon
      },
      deliveryIcon: {
        component: DeliveryTruckIcon
      },
      plusCircleIcon: {
        component: PlusCircleIcon
      },
      filterIcon: {
        component: FilterIcon
      },
      whatappIcon: {
        component: WhatappIcon
      },
      locationIcon: {
        component: LocationIcon
      },
      exclamationMarkIcon: {
        component: ExclamationMarkIcon
      },
      paymentMethodIcon: {
        component: PaymentMethodIcon
      },
      billingIcon: {
        component: BillingIcon
      },
      downloadIcon: {
        component: DownloadIcon
      },
      copyIcon: {
        component: CopyIcon
      }
    }
  },
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      dark: {
        'v-divider': '#769B6E'
      },
      light: {
        unbox_black: '#231F20',
        unbox_green: '#769B6E',
        unbox_secondary: {
          base: '#706967',
          lighten1: '#DACCBF'
        },
        unbox_red: '#9D4823',
        unbox_primary: '#C7A87E',
        border: '#D5DDE0',
        hint: '#FBBF24',
        red: '#EF4444',
        gray: '#64748B',
        khaki: '#C7A87E',
        light_gray: '#F1F5F9',
        text_light_gray: '#94A3B8',
        neutral_white: '#F8FAFC',
        success_01: '#34D399',
        hint_color: '#FCD34D',
        sharp_red: '#FF0000'
      }
    }
  }
})
