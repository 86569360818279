import { localeHelper } from '@/utils'
import { GENDER } from '@/constants/enums'
import { Country, State, City } from 'country-state-city'

export function getGenders(isStringValue) {
  let ddl = []

  ddl.push({
    text: localeHelper.getMessage(`enumGender[0].${GENDER.MALE}`),
    value: isStringValue ? GENDER.MALE.toString() : GENDER.MALE
  })

  ddl.push({
    text: localeHelper.getMessage(`enumGender[0].${GENDER.FEMALE}`),
    value: isStringValue ? GENDER.FEMALE.toString() : GENDER.FEMALE
  })

  return ddl
}

export function getCountryStates() {
  let ddl = []
  let states = State.getStatesOfCountry('MY')

  states.forEach((state) => {
    ddl.push({
      text: state.name,
      value: state.name,
      isoCode: state.isoCode
    })
  })

  return ddl
}

export function getCitiesOfState(stateCode) {
  let ddl = []
  let cities = City.getCitiesOfState('MY', stateCode)

  cities.forEach((city) => {
    ddl.push({
      text: city.name,
      value: city.name
    })
  })

  return ddl
}
