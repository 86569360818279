import { API } from '@/constants'
import { apiHelper } from '@/utils'

const API_URL = API.BASE_URL.replace('@serviceName', 'service') + '/v1'

const SERVICE = {
  LOOKUPS: 'lookups',
  SERVICES: 'services',
  TYPES: 'types',
  GROUPS: 'groups'
}

/* LOOKUP */
export function getServiceGroupLookup() {
  return apiHelper(API_URL, false).get(`/${SERVICE.LOOKUPS}/${SERVICE.SERVICES}/${SERVICE.GROUPS}`)
}

export function getServices() {
  return apiHelper(API_URL, false).get(`/${SERVICE.SERVICES}`)
}

export function getServiceByGroupid(data) {
  return apiHelper(API_URL, false).get(`/${SERVICE.SERVICES}/${data}`)
}
