import { API, SHARED } from '@/constants'
import { LOGIN_OPTION } from '@/constants/enums'
import { apiHelper } from '@/utils'

const API_URL = API.BASE_URL.replace('@serviceName', 'customer') + '/v1'

const CUSTOMER = {
  CUSTOMERS: 'customers',
  AUTH: 'auth',
  TOKEN: 'token',
  REVOCATION: 'revocation',
  PASSWORD: 'password',
  LINK: 'link',
  VALIDITY: 'validity',
  ADDRESS: 'address'
}

export function register(data) {
  let requestOptions = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    username: data.email,
    phone_code: data.mobileNumberCountryCode,
    phone_no: data.mobileNumber
  }
  return apiHelper(API_URL, false).post(`/${CUSTOMER.CUSTOMERS}`, requestOptions)
}

export function resetPassword(data) {
  let requestOptions = {
    token: data.token,
    password: data.password,
    password_confirmation: data.passwordConfirmation,
    purpose: data.purpose
  }
  return apiHelper(API_URL, false).post(`/${CUSTOMER.CUSTOMERS}/${CUSTOMER.PASSWORD}`, requestOptions)
}

export function getResetPasswordLink(data) {
  let requestOptions = {
    params: {
      medium: data.medium,
      purpose: data.purpose,
      username: data.email
    }
  }
  return apiHelper(API_URL, false).get(`/${CUSTOMER.CUSTOMERS}/${CUSTOMER.PASSWORD}/${CUSTOMER.LINK}`, requestOptions)
}

export function getInformation(data) {
  return apiHelper(API_URL, true).get(`/${CUSTOMER.CUSTOMERS}/${data.uuid}`)
}

export function updateInformation(data) {
  let requestOptions = {
    first_name: data.firstName,
    last_name: data.lastName,
    phone_code: data.phoneCode,
    phone_no: data.phone,
    birthday: data.birthday,
    gender: data.gender,
    address_1: data.addressOne,
    address_2: data.addressTwo,
    address_3: data.addressThree,
    postcode: data.postcode,
    city: data.city,
    state: data.state,
    passport_number: data.passportNumber,
    passport_expire: data.passportExpireDate,
    id_number: data.idNumber
  }
  return apiHelper(API_URL, true).put(`/${CUSTOMER.CUSTOMERS}/${data.uuid}`, requestOptions)
}

export function changePassword(data) {
  let requestOptions = {
    old_password: data.currentPassword,
    new_password: data.newPassword,
    new_password_confirmation: data.newConfirmPassword
  }
  return apiHelper(API_URL, true).put(`/${CUSTOMER.CUSTOMERS}/${data.uuid}/${CUSTOMER.PASSWORD}`, requestOptions)
}

export function addAddress(data) {
  let requestOptions = {
    display_name: data.name,
    phone_code: data.phoneCode,
    phone_no: data.phone,
    address_1: data.address,
    postcode: data.postcode,
    city: data.city,
    state: data.state,
    block: data.block,
    is_default_shipping: data.defaultShippingAddress,
    is_default_billing: data.defaultBillingAddress
  }
  return apiHelper(API_URL, true).post(`/${CUSTOMER.CUSTOMERS}/${data.uuid}/${CUSTOMER.ADDRESS}`, requestOptions)
}
export function updateAddress(data) {
  let requestOptions = {
    display_name: data.name,
    phone_code: data.phoneCode,
    phone_no: data.phone,
    address_1: data.address,
    postcode: data.postcode,
    city: data.city,
    state: data.state,
    block: data.block,
    is_default_shipping: data.defaultShippingAddress,
    is_default_billing: data.defaultBillingAddress
  }
  return apiHelper(API_URL, true).put(`/${CUSTOMER.CUSTOMERS}/${data.uuid}/${CUSTOMER.ADDRESS}/${data.addressUuid}`, requestOptions)
}

/* AUTH */
export function login(data) {
  let requestOptions = {
    email: data.email,
    password: data.password,
    domain: data.domain,
    platform: data.platform,
    option: LOGIN_OPTION.PASSWORD
  }
  return apiHelper(API_URL, false).post(`/${CUSTOMER.AUTH}/${CUSTOMER.TOKEN}`, requestOptions)
}

export function logout(data) {
  let requestOptions = {
    domain: data.domain,
    platform: data.platform
  }
  return apiHelper(API_URL, true).put(`/${CUSTOMER.AUTH}/${CUSTOMER.TOKEN}-${CUSTOMER.REVOCATION}`, requestOptions)
}

export function refreshToken(data) {
  let requestOptions = {
    platform: data.platform,
    domain: data.domain
  }
  return apiHelper(API_URL, true).put(`/${CUSTOMER.AUTH}/${CUSTOMER.TOKEN}`, requestOptions)
}

export function validateToken() {
  return apiHelper(API_URL, true).get(`/${CUSTOMER.AUTH}/${CUSTOMER.TOKEN}/${CUSTOMER.VALIDITY}`)
}

export function deleteAccount(data) {
  let requestOptions = {
    data: { password: data.password }
  }
  return apiHelper(API_URL, true).delete(`/${CUSTOMER.CUSTOMERS}/${data.uuid}`, requestOptions)
}
