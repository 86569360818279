<template>
  <div>
    <div class="text-body-2 font-weight-bold unbox_secondary--text mb-2">{{ label }}</div>
    <v-text-field
      v-if="type == 'text' || type == 'password'"
      :value="value"
      class="rounded-lg"
      :placeholder="placeholder"
      outlined
      background-color="white"
      :rules="readonly ? [] : isOptional ? (value.length > 0 ? rules : []) : rules"
      :type="type == 'password' ? (showPassword ? 'text' : 'password') : type"
      :readonly="readonly"
      :disabled="readonly"
      @click:append="type == 'password' ? (showPassword = !showPassword) : (showPassword = !showPassword)"
      @keydown.space="preventLeadingSpace"
      @input="input"
      @change="change"
      :prefix="prefix"
      v-mask="mask"
    >
      <template v-slot:append>
        <div class="mt-n4">
          <div v-if="type == 'password'">
            <v-icon @click="type == 'password' ? (showPassword = !showPassword) : (showPassword = !showPassword)" class="mt-4">{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
          </div>

          <v-btn v-if="!!appendButton" class="mt-2 white--text" color="#97A3B6" @click="appendButton.onClick" :disabled="appendButton.disabled">{{ appendButton.title }}</v-btn>
        </div>
      </template>
    </v-text-field>

    <v-select
      v-if="type == 'select'"
      :value="value"
      class="rounded-lg"
      :items="items"
      :placeholder="placeholder"
      outlined
      background-color="white"
      :readonly="readonly || items.length <= 0"
      :disabled="readonly || items.length <= 0"
      :rules="readonly ? [] : isOptional ? (value.length > 0 ? rules : []) : rules"
      :item-text="itemText"
      :item-value="itemValue"
      @input="input"
      @change="change"
    ></v-select>

    <v-textarea
      v-if="type == 'textarea'"
      :value="value"
      class="rounded-lg"
      :rows="rows"
      :placeholder="placeholder"
      outlined
      background-color="white"
      :rules="readonly ? [] : isOptional ? (value.length > 0 ? rules : []) : rules"
      :readonly="readonly"
      :disabled="readonly"
      @input="input"
      @change="change"
    ></v-textarea>
  </div>
</template>
<script>
export default {
  name: 'AppFormField',
  props: {
    value: {
      required: true,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    mask: {
      type: String,
      required: false
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    change: {
      type: Function,
      required: false,
      default: function () {}
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    isOptional: {
      type: Boolean,
      required: false,
      default: false
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    itemText: {
      type: String,
      required: false,
      default: 'text'
    },
    itemValue: {
      type: String,
      required: false,
      default: 'value'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    appendButton: {
      type: Object,
      required: false,
      default: undefined,
      disabled: false
    },
    rows: {
      type: Number,
      required: false,
      default: 5
    }
  },
  data: () => ({
    showPassword: false
  }),
  methods: {
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault()
      else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, '')
    },
    input(value) {
      this.$emit('input', value)
    }
  }
}
</script>
