<template>
  <v-container class="fill-height py-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card-text class="pa-0 pb-2 font-weight-bold title text-center">{{ dialogMessageTitle }}</v-card-text>
        <v-card-text class="pa-0 pb-2 body-1" v-for="(message, index) in dialogMessage" :key="`${message}-${index}`">{{ message }}</v-card-text>

        <div class="mt-4">
          <template v-for="(button, index) in dialogButton">
            <div :key="index">
              <app-button :type="button.type" :action="button.action" :customClass="button.class" :title="button.title"></app-button>
            </div>
          </template>
        </div>
        <div class="d-none text-right text-caption" v-if="dialogErrorCode != ''">
          <span>{{ dialogErrorCode }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { localeHelper } from '@/utils'
export default {
  name: 'AppPageDialog',
  props: {
    dialogMessageTitle: {
      type: String,
      required: false,
      default: localeHelper.getMessage(`label.system`)
    },
    dialogMessage: {
      type: Array,
      required: false,
      default: () => []
    },
    dialogButton: {
      type: Array,
      required: false,
      default: () => []
    },
    dialogErrorCode: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
