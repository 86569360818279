import { API, SHARED } from '@/constants'
import { apiHelper } from '@/utils'

const API_URL = API.BASE_URL.replace('@serviceName', 'product') + '/v1'

const PRODUCT = {
  LOOKUPS: 'lookups',
  BRANDS: 'brands',
  PRODUCTS: 'products',
  TYPES: 'types',
  CATEGORIES: 'categories',
  GROUPS: 'groups'
}

export function getProducts(data) {
  let requestOptions = {
    params: {
      sku: data.sku,
      supplier_sku: data.supplierSku,
      display_sku: data.displaySku,
      display_skus: data.displaySkus,
      name: data.name,
      model: data.model,
      supplier: data.supplier,
      brand_uuids: data.brands,
      tax: data.tax,
      type_uuid: data.type,
      category_uuid: data.categoryUuid,
      search_words: data.keyword != undefined ? [data.keyword] : data.keyword,
      supported_platform: SHARED.PLATFORM,
      min_price: data.prices != undefined ? (data.prices[0] == 0 ? 1 : data.prices[0]) : undefined,
      max_price: data.prices != undefined ? data.prices[1] : undefined,
      page: data.page,
      size: data.size,
      sortBy: data.sortBy,
      orderBy: data.orderBy == undefined || data.orderBy == 'latest' ? 'desc' : data.orderBy,
      status: data.status
    }
  }
  return apiHelper(API_URL, false).get(`/${PRODUCT.PRODUCTS}`, requestOptions)
}

export function getProductByGroup(data) {
  return apiHelper(API_URL, false).get(`/${PRODUCT.PRODUCTS}/${data.groupId}`)
}

/* LOOKUP */
export function getProductTypesLookup() {
  return apiHelper(API_URL, false).get(`/${PRODUCT.LOOKUPS}/${PRODUCT.TYPES}`)
}

export function getProductBrandsLookup() {
  return apiHelper(API_URL, false).get(`/${PRODUCT.LOOKUPS}/${PRODUCT.BRANDS}`)
}

export function getProductCategoriesLookup() {
  return apiHelper(API_URL, false).get(`/${PRODUCT.LOOKUPS}/${PRODUCT.CATEGORIES}`)
}

export function getProductGroupLookup(data) {
  let requestOptions = {
    params: {
      name: data.name,
      supported_platform: SHARED.PLATFORM
    }
  }
  return apiHelper(API_URL, false).get(`/${PRODUCT.LOOKUPS}/${PRODUCT.PRODUCTS}/${PRODUCT.GROUPS}`, requestOptions)
}
