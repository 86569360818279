import { API, SHARED } from '@/constants'
import { apiHelper } from '@/utils'

const API_URL = API.BASE_URL.replace('@serviceName', 'stock') + '/v1'

const STOCK = {
  OUTLET_STOCKs: 'outlet-stocks',
  DISPLAY_SKUS: 'display-skus',
  QUANTITY: 'quantity'
}

export function getStockLevelOnOutlet(data) {
  let requestOptions = {
    params: {
      outlet_uuid: data.outletUuid,
      product_name: data.productName,
      display_sku: data.displaySku,
      sku: data.sku
    }
  }
  return apiHelper(API_URL, false).get(`/${STOCK.OUTLET_STOCKs}/${STOCK.DISPLAY_SKUS}/${STOCK.QUANTITY}`, requestOptions)
}
