import { productService, orderService, paymentService, outletService, loanService, serviceService } from '@/services'
import { storageHelper, sharedHelper, localeHelper } from '@/utils'
import { CACHE_KEY, SHARED } from '@/constants'
require('fast-totree')
const MODULE_NAME = 'lookup/'

const A_LOOKUPS_PRODUCT_TYPES = 'lookupsProductTypes'
const A_LOOKUPS_PRODUCT_BRANDS = 'lookupsProductBrands'
const A_LOOKUPS_PRODUCT_CATEGORIES = 'lookupsProductCategories'
const A_LOOKUPS_ORDER_DELIVERY_METHODS = 'lookupsOrderDeliveryMethods'
const A_LOOKUPS_ORDER_STATUSES = 'lookupsOrderStatuses'
const A_LOOKUPS_PAYMENT_TYPES = 'lookupsPaymentTypes'
const A_LOOKUPS_OUTLET_OUTLETS = 'lookupsOutlets'
const A_LOOKUPS_PRODUCT_GROUPS = 'lookupsProductGroups'
const A_LOOKUPS_SERVICE_GROUPS = 'lookupsServiceGroups'
const A_LOOKUPS_INCOME_RANGES = 'lookupsIncomeRanges'
const A_LOOKUPS_CREDIT_CARDS = 'lookupsCreditCards'
const A_LOOKUPS_ORDER_RETURN_TYPE = 'lookupsOrderReturnType'
const A_LOOKUPS_CART_STATUSES = 'lookupsCartStatuses'
const A_LOOKUPS_PERSONAL_LOANS = 'lookupsPersonalLoans'

export const LOOKUPS_PRODUCT_TYPES = MODULE_NAME + A_LOOKUPS_PRODUCT_TYPES
export const LOOKUPS_PRODUCT_BRANDS = MODULE_NAME + A_LOOKUPS_PRODUCT_BRANDS
export const LOOKUPS_PRODUCT_CATEGORIES = MODULE_NAME + A_LOOKUPS_PRODUCT_CATEGORIES
export const LOOKUPS_ORDER_DELIVERY_METHODS = MODULE_NAME + A_LOOKUPS_ORDER_DELIVERY_METHODS
export const LOOKUPS_ORDER_STATUSES = MODULE_NAME + A_LOOKUPS_ORDER_STATUSES
export const LOOKUPS_PAYMENT_TYPES = MODULE_NAME + A_LOOKUPS_PAYMENT_TYPES
export const LOOKUPS_OUTLET_OUTLETS = MODULE_NAME + A_LOOKUPS_OUTLET_OUTLETS
export const LOOKUPS_PRODUCT_GROUPS = MODULE_NAME + A_LOOKUPS_PRODUCT_GROUPS
export const LOOKUPS_SERVICE_GROUPS = MODULE_NAME + A_LOOKUPS_SERVICE_GROUPS
export const LOOKUPS_INCOME_RANGES = MODULE_NAME + A_LOOKUPS_INCOME_RANGES
export const LOOKUPS_CREDIT_CARDS = MODULE_NAME + A_LOOKUPS_CREDIT_CARDS
export const LOOKUPS_ORDER_RETURN_TYPE = MODULE_NAME + A_LOOKUPS_ORDER_RETURN_TYPE
export const LOOKUPS_CART_STATUSES = MODULE_NAME + A_LOOKUPS_CART_STATUSES
export const LOOKUPS_PERSONAL_LOANS = MODULE_NAME + A_LOOKUPS_PERSONAL_LOANS

const M_LOOKUPS_PRODUCT_TYPES = 'setLookupsProductTypes'
const M_LOOKUPS_PRODUCT_BRANDS = 'setLookupsProductBrands'
const M_LOOKUPS_PRODUCT_CATEGORIES = 'setLookupsProductCategories'
const M_LOOKUPS_ORDER_DELIVERY_METHODS = 'setLookupsOrderDeliveryMethods'
const M_LOOKUPS_ORDER_STATUSES = 'setLookupsOrderStatuses'
const M_LOOKUPS_PAYMENT_TYPES = 'setLookupsPaymentTypes'
const M_LOOKUPS_OUTLET_OUTLETS = 'setLookupsOutlet'
const M_LOOKUPS_PRODUCT_GROUPS = 'setLookupsProductGroups'
const M_LOOKUPS_SERVICE_GROUPS = 'setLookupsServiceGroups'
const M_LOOKUPS_INCOME_RANGES = 'setLookupsIncomeRanges'
const M_LOOKUPS_CREDIT_CARDS = 'setLookupsCreditCards'
const M_LOOKUPS_ORDER_RETURN_TYPE = 'setLookupsOrderReturnType'
const M_LOOKUPS_CART_STATUSES = 'setLookupsCartStatuses'
const M_LOOKUPS_PERSONAL_LOANS = 'setLookupsPersonalLoans'

const G_CART_STATUS_UUID_BY_NAME = 'getOrderStatusUuidByName'

export const LOOKUP_CART_STATUS_UUID_BY_NAME = MODULE_NAME + G_CART_STATUS_UUID_BY_NAME

const state = {
  productTypes: [],
  productTypesTreeView: [],
  productBrands: [],
  productCategories: [],
  orderDeliveryMethods: [],
  orderStatuses: [],
  paymentTypes: [],
  outlets: [],
  productGroups: [],
  serviceGroups: [],
  incomeRanges: [],
  creditCards: [],
  orderReturnTypes: [],
  cartStatuses: [],
  personalLoans: []
}

const getters = {
  [G_CART_STATUS_UUID_BY_NAME]: (state) => (name) => {
    return state.cartStatuses.find((status) => status.name.toLowerCase() === name.toLowerCase()).value || ''
  }
}

const actions = {
  [A_LOOKUPS_PRODUCT_TYPES]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    productService
      .getProductTypesLookup()
      .then((data) => {
        commit(M_LOOKUPS_PRODUCT_TYPES, { data })
      })
      .catch((err) => {
        commit(M_LOOKUPS_PRODUCT_TYPES, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOOKUPS_PRODUCT_BRANDS]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    productService
      .getProductBrandsLookup()
      .then((data) => {
        commit(M_LOOKUPS_PRODUCT_BRANDS, { data })
      })
      .catch((err) => {
        commit(M_LOOKUPS_PRODUCT_BRANDS, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOOKUPS_PRODUCT_CATEGORIES]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    productService
      .getProductCategoriesLookup()
      .then((data) => {
        commit(M_LOOKUPS_PRODUCT_CATEGORIES, { data })
      })
      .catch((err) => {
        commit(M_LOOKUPS_PRODUCT_CATEGORIES, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  async [A_LOOKUPS_ORDER_DELIVERY_METHODS]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    await orderService
      .getDeliveryMethodsLookup()
      .then((data) => {
        commit(M_LOOKUPS_ORDER_DELIVERY_METHODS, { data })
      })
      .catch((err) => {
        commit(M_LOOKUPS_ORDER_DELIVERY_METHODS, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  async [A_LOOKUPS_ORDER_STATUSES]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    await orderService
      .getOrderStatuses()
      .then((data) => {
        commit(M_LOOKUPS_ORDER_STATUSES, { data })
      })
      .catch((err) => {
        commit(M_LOOKUPS_ORDER_STATUSES, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOOKUPS_PAYMENT_TYPES]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    paymentService
      .getPaymentTypes()
      .then((data) => {
        commit(M_LOOKUPS_PAYMENT_TYPES, { data })
      })
      .catch((err) => {
        commit(M_LOOKUPS_PAYMENT_TYPES, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  async [A_LOOKUPS_OUTLET_OUTLETS]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    await outletService
      .getOutlets()
      .then((data) => {
        commit(M_LOOKUPS_OUTLET_OUTLETS, { data })
      })
      .catch((err) => {
        commit(M_LOOKUPS_OUTLET_OUTLETS, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOOKUPS_PRODUCT_GROUPS]({ commit, dispatch }, { data }) {
    return productService
      .getProductGroupLookup(data)
      .then((result) => {
        commit(M_LOOKUPS_PRODUCT_GROUPS, { result })
      })
      .catch((err) => {
        commit(M_LOOKUPS_PRODUCT_GROUPS, { err })
      })
      .finally(() => {})
  },
  [A_LOOKUPS_SERVICE_GROUPS]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    return serviceService
      .getServiceGroupLookup()
      .then((result) => {
        commit(M_LOOKUPS_SERVICE_GROUPS, { result })
      })
      .catch((err) => {
        commit(M_LOOKUPS_SERVICE_GROUPS, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOOKUPS_INCOME_RANGES]({ commit, dispatch }) {
    loanService
      .getIncomeRangesLookup()
      .then((result) => {
        commit(M_LOOKUPS_INCOME_RANGES, { result })
      })
      .catch((err) => {
        commit(M_LOOKUPS_INCOME_RANGES, { err })
      })
      .finally(() => {})
  },
  [A_LOOKUPS_CREDIT_CARDS]({ commit, dispatch }) {
    loanService
      .getCreditCardsLookup()
      .then((result) => {
        commit(M_LOOKUPS_CREDIT_CARDS, { result })
      })
      .catch((err) => {
        commit(M_LOOKUPS_CREDIT_CARDS, { err })
      })
      .finally(() => {})
  },
  [A_LOOKUPS_ORDER_RETURN_TYPE]({ commit, dispatch }) {
    orderService
      .lookupReturnType()
      .then((result) => {
        commit(M_LOOKUPS_ORDER_RETURN_TYPE, result)
      })
      .catch((error) => {
        commit(M_LOOKUPS_ORDER_RETURN_TYPE, { error })
      })
  },
  async [A_LOOKUPS_CART_STATUSES]({ state, commit, dispatch }) {
    if (state.cartStatuses.length > 0) return
    sharedHelper.loading(dispatch)
    await orderService
      .getCartStatuses()
      .then((data) => {
        commit(M_LOOKUPS_CART_STATUSES, { data })
      })
      .catch((err) => {
        commit(M_LOOKUPS_CART_STATUSES, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOOKUPS_PERSONAL_LOANS]({ commit, dispatch }) {
    loanService
      .getPersonalLoansLookup()
      .then((result) => {
        commit(M_LOOKUPS_PERSONAL_LOANS, { result })
      })
      .catch((err) => {
        commit(M_LOOKUPS_PERSONAL_LOANS, { err })
      })
      .finally(() => {})
  }
}

function pushData(item, masterData, insertItem) {
  let object = masterData.find((y) => y.uuid == item.uuid)
  let p = []

  if (insertItem) {
    p.push(object)
  }

  if (object.sub_types.length > 0) {
    object.sub_types.forEach((x) => {
      p.push(masterData.find((y) => y.uuid == x.uuid))
    })
  }

  if (object.sub_types.length > 0) {
    object.sub_types.forEach((x) => {
      p = p.concat(pushData(x, masterData, false))
    })
  }

  return p
}

const mutations = {
  [M_LOOKUPS_PRODUCT_TYPES](state, { data }) {
    let masterTierItem = data.data.filter((x) => x.tier == 1)

    let p = []

    masterTierItem.forEach((x) => {
      p = p.concat(pushData(x, data.data, true))
    })

    p.forEach((o) => {
      state.productTypes.push({
        id: o.uuid,
        value: o.uuid,
        uuid: o.uuid,
        text: o.name,
        tier: o.tier,
        imageUrl: `${SHARED.MEDIA_SERVER_URL}/${o.image_path}`,
        parentUuid: o.parent_type == null ? 0 : o.parent_type.uuid,
        parentId: o.parent_type == null ? 0 : o.parent_type.uuid
      })
    })

    state.productTypesTreeView = state.productTypes.toTree()

    storageHelper.setLocalStorage(`${CACHE_KEY.PRODUCT_TYPE}`, state.productTypes, 3000)
    storageHelper.setLocalStorage(`${CACHE_KEY.PRODUCT_TYPE_TREEVIEW}`, state.productTypesTreeView, 3000)
  },
  [M_LOOKUPS_PRODUCT_BRANDS](state, { data }) {
    let filterableBrand = SHARED.FILTERABLE_BRAND
    let p = []
    state.productBrands = []

    p = data.data.map((o) => {
      return {
        value: o.code,
        text: o.name,
        code: o.code,
        slogan: o.slogan,
        logoUrl: o.logo_url,
        uuid: o.uuid
      }
    })

    filterableBrand.forEach((x) => {
      let brandInfo = p.find((y) => y.value.toLowerCase() == x.toLowerCase())
      if (brandInfo != undefined) {
        state.productBrands.push(brandInfo)
      }
    })
  },
  [M_LOOKUPS_PRODUCT_CATEGORIES](state, { data }) {
    state.productCategories = data.data.map((o) => {
      return {
        value: o.uuid,
        uuid: o.uuid,
        text: o.name,
        tier: o.tier,
        parentCategory: o.parent_category,
        subCategories: o.sub_categories
      }
    })
  },
  [M_LOOKUPS_ORDER_DELIVERY_METHODS](state, { data }) {
    state.orderDeliveryMethods = data.data.map((o) => {
      return {
        value: o.uuid,
        uuid: o.uuid,
        text: localeHelper.getMessage(`deliveryMethods.${o.name.toLowerCase().replaceAll(' ', '')}`),
        name: o.name.toLowerCase().replaceAll(' ', '')
      }
    })
  },
  [M_LOOKUPS_ORDER_STATUSES](state, { data }) {
    state.orderStatuses = data.data.map((o) => {
      return {
        value: o.uuid,
        uuid: o.uuid,
        name: o.name.toLowerCase(),
        text: localeHelper.getMessage(`orderStatuses.${o.name.toLowerCase()}`)
      }
    })
  },
  [M_LOOKUPS_PAYMENT_TYPES](state, { data }) {
    state.paymentTypes = data.data.map((o) => {
      return {
        value: o.uuid,
        uuid: o.uuid,
        text: localeHelper.getMessage(`paymentTypes.${o.name.toLowerCase()}`)
      }
    })
  },
  [M_LOOKUPS_OUTLET_OUTLETS](state, { data }) {
    state.outlets = data.data.map((o) => {
      return {
        value: o.uuid,
        uuid: o.uuid,
        text: `${o.external_name} (${o.name})`,
        externalName: o.external_name,
        name: o.name,
        addressOne: o.address_1,
        addressTwo: o.address_2,
        addressThree: o.address_3,
        fullAddress:
          o.address_1 +
          (o.address_2 != null ? (o.address_2.endsWith(',') ? ' ' + o.address_2 : ', ' + o.address_2) : '') +
          (o.address_3 != null ? (o.address_3.endsWith(',') ? ' ' + o.address_3 : ', ' + o.address_3 + ', ') : ''),
        postcode: o.postcode,
        city: o.city,
        state: o.state,
        phoneCode: o.phone_code,
        phone: o.phone,
        latitude: o.latitude,
        longtitude: o.longitude,
        operationTime: o.operation_time,
        mapUrl: o.map_url,
        googleReviewUrl: o.review_url,
        storeImage: o.image_path,
        operateDesc: o.operate_description
      }
    })
  },
  [M_LOOKUPS_PRODUCT_GROUPS](state, { result }) {
    state.productGroups = result.data.map((o) => {
      return {
        value: o.group,
        text: o.name
      }
    })
  },
  [M_LOOKUPS_SERVICE_GROUPS](state, { result }) {
    state.serviceGroups = result.data.map((o) => {
      return {
        value: o.group,
        name: o.name,
        productName: o.product_name
      }
    })
  },
  [M_LOOKUPS_INCOME_RANGES](state, { result }) {
    state.incomeRanges = result.data.map((o) => {
      return {
        uuid: o.uuid,
        ranges: o.ranges
      }
    })
  },
  [M_LOOKUPS_CREDIT_CARDS](state, { result }) {
    state.creditCards = result.data.map((o) => {
      return {
        uuid: o.uuid,
        name: o.name,
        minIncomeRequirement: o.min_income_requirement,
        minAgeRequirement: o.min_age_requirement,
        title: o.title,
        features: o.features,
        requirement: o.requirement,
        imgUrl: o.img_url,
        tncUrl: o.tnc_url,
        faqUrl: o.faq_url,
        pdsUrl: o.pds_url
      }
    })
  },
  [M_LOOKUPS_ORDER_RETURN_TYPE](state, { data }) {
    state.orderReturnTypes = data.map((o) => ({
      text: localeHelper.getMessage(`orderReturnTypes.${o.name.toLowerCase()}`),
      value: o.name
    }))
  },
  [M_LOOKUPS_CART_STATUSES](state, { data }) {
    state.cartStatuses = data.data.map((o) => {
      return {
        value: o.uuid,
        uuid: o.uuid,
        name: o.name.toLowerCase()
      }
    })
  },
  [M_LOOKUPS_PERSONAL_LOANS](state, { result }) {
    state.personalLoans = result.data.map((o) => {
      return {
        uuid: o.uuid,
        name: o.name,
        minIncomeRequirement: o.min_income_requirement,
        minAgeRequirement: o.min_age_requirement,
        title: o.title,
        features: o.features,
        requirement: o.requirement,
        imgUrl: o.img_url,
        tncUrl: o.tnc_url,
        pdsUrl: o.pds_url,
        redirectUrl: o.redirect_url,
        minLoanAmount: o.min_loan_amount,
        maxLoanAmount: o.max_loan_amount,
        minLoanTenureYear: o.min_loan_tenure_year,
        maxLoanTenureYear: o.max_loan_tenure_year
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
