import { API, SHARED } from '@/constants'
import { apiHelper } from '@/utils'

const API_URL = API.BASE_URL.replace('@serviceName', 'outlet') + '/v1'

const OUTLET = {
  LOOKUPS: 'lookups',
  OUTLETS: 'outlets',
  INT_OUTLET_LIST: 'int/outlets'
}

/* LOOKUP */
export function getOutlets() {
  return apiHelper(API_URL, true).get(`/${OUTLET.LOOKUPS}/${OUTLET.OUTLETS}?platform=web`)
}
