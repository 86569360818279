export const ROUTE_NAME = Object.freeze({
  HOME: 'home',
  CREATE_PASSWORD: 'create-password',
  RESET_PASSWORD: 'reset-password',
  ACCOUNT: 'account',
  CHANGE_PASSWORD: 'change-password',
  DELIVERY_ADDRESSES: 'delivery-addresses',
  DELETE_ACCOUNT: 'delete-account',
  PAYMENT_RESULT: 'payment-result',
  PAYMENT_SUCCESS: 'payment-success',
  PAYMENT_CANCEL: 'payment-cancel',
  PAYMENT_FAIL: 'payment-fail',
  PAYMENT_COMPLETE_SUCCESS: 'payment-complete-success',
  PAYMENT_COMPLETE_CANCEL: 'payment-complete-cancel',
  PAYMENT_COMPLETE_FAIL: 'payment-complete-fail',
  PAYMENT_CHECK: 'payment-check',
  ORDER_SUMMARY_PAGE: 'order-summary',
  SHOP: 'shop',
  SHOP_WITH_TYPE: 'shop-with-type',
  SHOP_WITH_KEYWORD: 'shop-with-keyword',
  SHOP_PRODUCT: 'shop-product',
  SHOP_CART: 'shop-cart',
  SHOP_CHECKOUT: 'shop-checkout',
  SHOP_PREORDER_CHECKOUT: 'shop-preorder-checkout',
  HISTORY_HISTORY: 'history-history',
  PRIVACY_POLICY: 'privacy-policy',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  ABOUT_US: 'about-us',
  PROTECTION_PLAN: 'protection-plan',
  CONTACT_US: 'contact-us',
  REFUND_POLICY: 'refund-policy',
  CAREER: 'career',
  BUY_NOW_PAY_LATER: 'buy-now-pay-later',
  APP_DOWNLOAD: 'app-download',
  CLASS_REGISTRATION: 'class-registration',
  CREDIT_CARD: 'credit-card',
  CREDIT_CARD_APPLY: 'credit-card-apply',
  PERSONAL_LOAN: 'personal-loan',
  PERSONAL_LOAN_APPLY: 'personal-loan-apply',
  BANNER_REDIRECT_URL_OPPO: 'oppo',
  BANNER_REDIRECT_URL_SNBL_LANDING: 'snbl-landing',
  BANNER_REDIRECT_URL_SNBL_CS: 'snbl-cs',
  TRADE_IN: 'trade-in',
  LOCATE_US: 'locate-us'
})
