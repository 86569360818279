import { PLATFORM } from '@/constants/enums'
import { REGEX, SESSION, DATE, EXTERNAL_PRODUCT } from '@/constants'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import moment from 'moment'
let currencyFormatter = require('currency-formatter')

export function isAuthenticated() {
  let isLoggedIn = localStorage.getItem(SESSION.JWT_TOKEN)
  let result = true
  if (!isLoggedIn) {
    result = false
  }
  return result
}

export function loading(dispatch) {
  dispatch(SHARED_LOADING, {}, { root: true })
}

export function unloading(dispatch) {
  dispatch(SHARED_UNLOADING, {}, { root: true })
}

export function getHostname() {
  return window.location.origin
}

export function getHost() {
  return window.location.host
}

export function getPlatform() {
  let type = PLATFORM.DESKTOP
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/is) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    type = PLATFORM.MOBILE
  } else {
    type = PLATFORM.DESKTOP
  }
  return type
}

export function IsIos() {
  if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/is) || navigator.userAgent.match(/iPod/i)) {
    return true
  }
}

export function formatCurrency(value) {
  return currencyFormatter.format(value, { code: 'MYR' })
}

export function getParent(array, target) {
  let result
  array.some(({ id, chidren = [] }) => {
    if (id === target) return (result = id)
    let temp = getParent(chidren, target)
    if (temp) return (result = id + '.' + temp)
  })

  return result
}

export const getBirthdayDateFromIdentityNumber = (identityNumber) => {
  if (identityNumber.match(REGEX.MALAYSIAN_IDENTITY_NUMBER)) {
    let year = RegExp.$2
    let month = RegExp.$3
    let day = RegExp.$4
    let now = new Date().getFullYear().toString()

    let decade = now.substr(0, 2)

    if (now.substr(2, 2) > year) {
      year = parseInt(decade.concat(year.toString()), 10)
    } else {
      year = '19' + year
    }

    let d = new Date(year, month - 1, day)
    if (d.getFullYear() == year && d.getMonth() == month - 1 && d.getDate() == day) {
      let date = moment(new Date(year, month - 1, day, 0, 0, 0, 0))
      return date.format('YYYY-MM-DD')
    }
  }
}

export function setShopSearchFilter(key, param) {
  let shopSearchCriteria = localStorage.getItem(SESSION.SHOP_SEARCH)
  if (shopSearchCriteria == null) {
    shopSearchCriteria = {
      category: '',
      brand: '',
      price: '',
      keyword: '',
      page: '',
      orderBy: '',
      sortBy: ''
    }
    shopSearchCriteria = JSON.stringify(shopSearchCriteria)
  }
  let data = JSON.parse(shopSearchCriteria)
  data[key] = param
  localStorage.setItem(SESSION.SHOP_SEARCH, JSON.stringify(data))

  if (data.category == '') delete data.category
  if (data.brand == '') delete data.brand
  if (data.price == '') delete data.price
  if (data.keyword == '') delete data.keyword
  if (data.page == '') delete data.page
  if (data.orderBy == '') delete data.orderBy
  if (data.sortBy == '') delete data.sortBy
  return data
}

export function getShopSearchFilter() {
  let shopSearchCriteria = localStorage.getItem(SESSION.SHOP_SEARCH)
  if (shopSearchCriteria == null) {
    shopSearchCriteria = {
      category: '',
      brand: '',
      price: '',
      keyword: '',
      page: ''
    }
    shopSearchCriteria = JSON.stringify(shopSearchCriteria)
  }
  let data = JSON.parse(shopSearchCriteria)

  if (data.category == '') delete data.category
  if (data.brand == '') delete data.brand
  if (data.price == '') delete data.price
  if (data.keyword == '') delete data.keyword
  if (data.page == '') delete data.page
  if (data.orderBy == '') delete data.orderBy
  if (data.sortBy == '') delete data.sortBy

  return data
}

export function calculateAeonLoanInstallment(price, haveWarranty, insurancePrice) {
  let totalAmount = price
  let interestRate = 0.0125
  let interestPeriod = 24
  let totalInsterest = 0
  let totalPayable = 0
  totalAmount += haveWarranty ? insurancePrice : 0

  totalInsterest = totalAmount * interestRate * interestPeriod
  totalPayable = totalAmount + totalInsterest

  return formatCurrency(totalPayable / interestPeriod)
}

export function addToBuyNowCart(productName, product, orderProductType, quantity, groupId, insuranceType, addOnInsurance) {
  let insurance = null
  if (insuranceType) {
    let tierInsurance = EXTERNAL_PRODUCT.INSURANCE[insuranceType.toUpperCase()].tier.find((x) => x.minCoverPrice <= product.discountedPrice && x.maxCoverPrice >= product.discountedPrice)

    if (tierInsurance != undefined) {
      insurance = {
        quantity: 1,
        sku: tierInsurance.sku,
        display_sku: tierInsurance.display_sku,
        unit_price: tierInsurance.unit_price,
        remark: `${productName}\n${product.option
          .map((o) => {
            return o.value
          })
          .join('/')}\n${product.sku}`
      }
    }
  }
  let productItem = {
    productName: productName,
    variation: product.option
      .map((o) => {
        return o.value
      })
      .join('/'),
    productDisplaySku: product.sku,
    quantity: quantity,
    unitPrice: product.sellingPrice,
    discountedPrice: product.discountedPrice,
    product_type: orderProductType,
    group: groupId,
    inCoverableByInsurance: insurance != null,
    requiredInsurance: addOnInsurance,
    insurance: insurance
  }

  const cart = [productItem]

  localStorage.setItem(SESSION.BUYNOW_CART, JSON.stringify(cart))
}

export function addToCart(productName, product, orderProductType, quantity, groupId, insuranceType, addOnInsurance) {
  let insurance = null
  if (insuranceType) {
    let tierInsurance = EXTERNAL_PRODUCT.INSURANCE[insuranceType.toUpperCase()].tier.find((x) => x.minCoverPrice <= product.discountedPrice && x.maxCoverPrice >= product.discountedPrice)

    if (tierInsurance != undefined) {
      insurance = {
        quantity: 1,
        sku: tierInsurance.sku,
        display_sku: tierInsurance.display_sku,
        unit_price: tierInsurance.unit_price,
        remark: `${productName}\n${product.option
          .map((o) => {
            return o.value
          })
          .join('/')}\n${product.sku}`
      }
    }
  }
  let productItem = {
    name: productName,
    variantion: product.option
      .map((o) => {
        return o.value
      })
      .join('/'),
    display_sku: product.sku,
    quantity: quantity,
    unit_price: product.discountedPrice,
    product_type: orderProductType,
    group: groupId,
    inCoverableByInsurance: insurance != null,
    requiredInsurance: addOnInsurance,
    insurance: insurance
  }

  const cart = JSON.parse(localStorage.getItem(SESSION.CART)) ?? { product: [] }

  const isExistingProduct = cart.product.find((x) => x.display_sku == product.sku)
  if (isExistingProduct === undefined) {
    cart.product.push(productItem)
  } else {
    isExistingProduct.quantity += quantity
    if (!!insuranceType && isExistingProduct.requiredInsurance) {
      isExistingProduct.insurance.quantity = isExistingProduct.quantity
    }
  }

  localStorage.setItem(SESSION.CART, JSON.stringify(cart))
}

export function setCartItems(newCartItems) {
  const cart = JSON.parse(localStorage.getItem(SESSION.CART))

  if (cart) {
    cart.product = newCartItems

    localStorage.setItem(SESSION.CART, JSON.stringify(cart))
  }
}

export function getDateFormat(value, dateFormat = DATE.LONG) {
  let desc = ''

  if (value != undefined && value != null && value != '') {
    let dateTimeGMT8 = new Date(value.toLocaleString('en-US', { timeZone: 'Asia/Singapore' }))
    desc = moment(dateTimeGMT8).format(dateFormat)
  }

  return desc
}
