import { SESSION } from '@/constants'

export function setCookie(name, value, day = 100) {
  let date = new Date()
  let expiration = new Date(date.setDate(date.getDate() + day))
  let cookie = escape(name) + '=' + escape(value) + ';expires=' + expiration + '; path=/'
  document.cookie = cookie
}

export function getCookie(name) {
  let key = name + '='
  let cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length)
    }
  }
  return null
}

export function removeCookie(name) {
  let date = new Date()
  let expiration = new Date(date.setDate(date.getDate() - 1))
  let cookie = escape(name) + '=' + ';expires=' + expiration + ';'
  document.cookie = cookie
}

export function getLocalStorage(key) {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

export function setLocalStorage(key, value, ttl) {
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + ttl * 60000
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key)
}

export function getUserToken() {
  return localStorage.getItem(SESSION.JWT_TOKEN)
}

export function getUsername() {
  let username = localStorage.getItem(SESSION.USERNAME)

  if (username) {
    return username.toLowerCase()
  } else {
    return ''
  }
}

export function clearBrowserStorage() {
  localStorage.clear()
  sessionStorage.clear()
}
