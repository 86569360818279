<template>
  <svg width="134" height="135" viewBox="0 0 134 135" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M67.2324 17.5176C94.8366 17.5176 117.215 39.8954 117.215 67.5C117.215 95.1042 94.8366 117.482 67.2324 117.482C39.6278 117.482 17.25 95.1042 17.25 67.5C17.25 39.8954 39.6278 17.5176 67.2324 17.5176Z"
      fill="#F87171"
    />
    <g opacity="0.01">
      <path
        opacity="0.01"
        d="M67.2319 2.73047C103.003 2.73047 132.002 31.7291 132.002 67.5005C132.002 103.272 103.003 132.271 67.2319 132.271C31.4605 132.271 2.46191 103.272 2.46191 67.5005C2.46191 31.7291 31.4605 2.73047 67.2319 2.73047Z"
        stroke="#F3564D"
        stroke-width="3.6"
        stroke-miterlimit="10"
      />
    </g>
    <g opacity="0.518604">
      <path
        opacity="0.518604"
        d="M67.232 11.8379C97.9746 11.8379 122.897 36.7598 122.897 67.5025C122.897 98.2451 97.9746 123.167 67.232 123.167C36.4893 123.167 11.5674 98.2451 11.5674 67.5025C11.5674 36.7598 36.4893 11.8379 67.232 11.8379Z"
        stroke="#F3564D"
        stroke-width="3.09391"
        stroke-miterlimit="10"
      />
    </g>
    <path
      d="M67.232 80.0948C64.43 80.0948 62.1582 77.823 62.1582 75.0207V43.827C62.1582 41.0247 64.43 38.7529 67.232 38.7529C70.0343 38.7529 72.3057 41.0247 72.3057 43.827V75.0207C72.3057 77.823 70.0343 80.0948 67.232 80.0948Z"
      fill="white"
    />
    <path
      d="M67.2321 96.2475C64.1187 96.2475 61.5947 93.7238 61.5947 90.6101C61.5947 87.4966 64.1187 84.9727 67.2321 84.9727C70.3456 84.9727 72.8695 87.4966 72.8695 90.6101C72.8695 93.7238 70.3456 96.2475 67.2321 96.2475Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'dialogExclamation'
}
</script>
