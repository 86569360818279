import { paymentService } from '@/services'
import { sharedHelper, storageHelper, localeHelper } from '@/utils'
import { SESSION, KEY, SHARED } from '@/constants'
const MODULE_NAME = 'payment/'

const A_PAYMENT_GATEWAY = 'paymentGateway'
const A_PAYMENT_MAKE_PAYMENT = 'paymentMakePayment'
const A_PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID = 'getPaymentTransactionByMerchantTransactionId'

export const PAYMENT_GATEWAY = MODULE_NAME + A_PAYMENT_GATEWAY
export const PAYMENT_MAKE_PAYMENT = MODULE_NAME + A_PAYMENT_MAKE_PAYMENT
export const PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID = MODULE_NAME + A_PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID

const M_PAYMENT_GATEWAY = 'setPaymentGateway'
const M_PAYMENT_MAKE_PAYMENT = 'paymentMakePaymentComplete'
const M_PAYMENT_RESET_MAKE_PAYMENT_STORE = 'paymentResetMakePaymentCompleteStore'
const M_PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID = 'setPaymentTransactionByMerchantTransactionId'
const M_PAYMENT_RESET_TRANSACTION_BY_MERCHANT_TRANSACTION_ID_STORE = 'resetPaymentTransactionByMerchantTransactionIdStore'

const state = {
  paymentTransaction: {},
  paymentGateways: [],
  makePaymentResponse: {
    action: 'payment',
    complete: false,
    success: false,
    code: 0
  },
  paymentTransactionInfo: {}
}

const getters = {}

const actions = {
  [A_PAYMENT_GATEWAY]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    paymentService
      .getPaymentGateways(data)
      .then((result) => {
        commit(M_PAYMENT_GATEWAY, { result })
      })
      .catch((result) => {
        commit(M_PAYMENT_GATEWAY, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_PAYMENT_MAKE_PAYMENT]({ commit, dispatch }, { data }) {
    console.log(data)
    commit(M_PAYMENT_RESET_MAKE_PAYMENT_STORE)
    sharedHelper.loading(dispatch)
    paymentService
      .makePayment(data)
      .then((result) => {
        commit(M_PAYMENT_MAKE_PAYMENT, { result })
      })
      .catch((result) => {
        commit(M_PAYMENT_MAKE_PAYMENT, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID]({ commit, dispatch }, { data }) {
    commit(M_PAYMENT_RESET_TRANSACTION_BY_MERCHANT_TRANSACTION_ID_STORE)
    sharedHelper.loading(dispatch)
    paymentService
      .getPaymentTransactionByMerchantTransactionId(data)
      .then((result) => {
        commit(M_PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID, { result })
      })
      .catch((result) => {
        commit(M_PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  }
}

const mutations = {
  [M_PAYMENT_GATEWAY](state, { result }) {
    let d = result.data

    let p = []

    d.forEach((o) => {
      p.push({
        id: o.uuid,
        value: o.uuid,
        uuid: o.uuid,
        text: localeHelper.getMessage(`paymentMethods.${o.payment_gateway.toLowerCase().replaceAll(' ', '')}`),
        name: o.payment_gateway,
        info: o.payment_info,
        type: o.payment_types
      })
    })

    let sorting = SHARED.PAYMENT_TYPE_SORTING

    p.sort(function (a, b) {
      if (sorting.indexOf(a.type[0].payment_type) > sorting.indexOf(b.type[0].payment_type)) {
        return 1
      } else if (sorting.indexOf(a.type[0].payment_type) == sorting.indexOf(b.type[0].payment_type)) {
        return 1
      } else {
        return -1
      }
    })

    state.paymentGateways = p
  },
  [M_PAYMENT_MAKE_PAYMENT](state, { result }) {
    state.makePaymentResponse = {
      action: 'payment',
      complete: true,
      success: result.success,
      code: result.code
    }

    if (result.success) {
      let d = result.data
      state.paymentTransaction = d
    }
  },
  [M_PAYMENT_RESET_MAKE_PAYMENT_STORE](state) {
    state.makePaymentResponse = {
      action: 'payment',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID](state, { result }) {
    if (result.success && result.data.length > 0) {
      let filterResult, d
      if (result.data.filter((x) => x.status.toLowerCase() == 'success').length > 0) {
        filterResult = result.data.filter((x) => x.status.toLowerCase() == 'success')
        d = filterResult[0]
      } else if (result.data.filter((x) => x.status.toLowerCase() == 'fail').length > 0) {
        filterResult = result.data.filter((x) => x.status.toLowerCase() == 'fail')
        d = filterResult[0]
      } else {
        d = result.data[0]
      }

      state.paymentTransactionInfo = {
        action: d.action,
        amount: d.amount,
        createdBy: d.created_by,
        createdDateTime: d.created_date_time,
        currency: d.currency,
        merchantTransactionReferenceId: d.merchant_trx_reference_id,
        platform: d.platform,
        status: d.status,
        transactionReferenceId: d.trx_reference_id,
        updatedBy: d.updated_by,
        updatedDateTime: d.updated_date_time,
        uuid: d.uuid
      }
    }
  },
  [M_PAYMENT_RESET_TRANSACTION_BY_MERCHANT_TRANSACTION_ID_STORE](state) {
    state.paymentTransactionInfo = { status: 'incomplete' }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
