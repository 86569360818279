import { customerService } from '@/services'
import { sharedHelper, storageHelper, localeHelper } from '@/utils'
import { SESSION, KEY, SHARED } from '@/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import { SHARED_CLOSE_DIALOG, SHARED_SHOW_DIALOG } from './shared.module'
import aes256 from 'aes256'
import router from '@/router'
const MODULE_NAME = 'customer/'

const A_CUSTOMER_REGISTER = 'customerRegister'
const A_CUSTOMER_LOGIN = 'customerLogin'
const A_CUSTOMER_LOGOUT = 'customerLogout'
const A_CUSTOMER_REFRESH_TOKEN = 'customerRefreshToken'
const A_CUSTOMER_RESET_PASSWORD = 'customerResetPassword'
const A_CUSTOMER_GET_RESET_PASSWORD_LINK = 'customerGetResetPasswordLink'
const A_CUSTOMER_VALIDATE_TOKEN = 'customerValidateToken'
const A_CUSTOMER_GET_INFORMATION = 'customerGetInformation'
const A_CUSTOMER_UPDATE_INFORMATION = 'customerUpdateInformation'
const A_CUSTOMER_CHANGE_PASSWORD = 'customerChangePassword'
const A_CUSTOMER_ADD_ADDRESS = 'customerAddAddress'
const A_CUSTOMER_EDIT_ADDRESS = 'customerEditAddress'
const A_CUSTOMER_FORGOT_PASSWORD = 'customerForgotPasswor'
const A_CUSTOMER_DELETE_ACCOUNT = 'customerDeleteAccount'

export const CUSTOMER_REGISTER = MODULE_NAME + A_CUSTOMER_REGISTER
export const CUSTOMER_LOGIN = MODULE_NAME + A_CUSTOMER_LOGIN
export const CUSTOMER_LOGOUT = MODULE_NAME + A_CUSTOMER_LOGOUT
export const CUSTOMER_REFRESH_TOKEN = MODULE_NAME + A_CUSTOMER_REFRESH_TOKEN
export const CUSTOMER_RESET_PASSWORD = MODULE_NAME + A_CUSTOMER_RESET_PASSWORD
export const CUSTOMER_GET_RESET_PASSWORD_LINK = MODULE_NAME + A_CUSTOMER_GET_RESET_PASSWORD_LINK
export const CUSTOMER_VALIDATE_TOKEN = MODULE_NAME + A_CUSTOMER_VALIDATE_TOKEN
export const CUSTOMER_GET_INFORMATION = MODULE_NAME + A_CUSTOMER_GET_INFORMATION
export const CUSTOMER_UPDATE_INFORMATION = MODULE_NAME + A_CUSTOMER_UPDATE_INFORMATION
export const CUSTOMER_CHANGE_PASSWORD = MODULE_NAME + A_CUSTOMER_CHANGE_PASSWORD
export const CUSTOMER_ADD_ADDRESS = MODULE_NAME + A_CUSTOMER_ADD_ADDRESS
export const CUSTOMER_EDIT_ADDRESS = MODULE_NAME + A_CUSTOMER_EDIT_ADDRESS
export const CUSTOMER_FORGOT_PASSWORD = MODULE_NAME + A_CUSTOMER_FORGOT_PASSWORD
export const CUSTOMER_DELETE_ACCOUNT = MODULE_NAME + A_CUSTOMER_DELETE_ACCOUNT

const M_CUSTOMER_FAILURE = 'customerFailure'
const M_CUSTOMER_REGISTER = 'customerRegisterComplete'
const M_CUSTOMER_RESET_REGISTER_STORE = 'customerResetRegisterStoreComplete'
const M_CUSTOMER_LOGIN = 'customerLoginComplete'
const M_CUSTOMER_LOGOUT = 'customerLogoutComplete'
const M_CUSTOMER_RESET_LOGOUT_STORE = 'customerReseLogoutStoreComplete'
const M_CUSTOMER_RESET_LOGIN_STORE = 'customerResetLoginStoreComplete'
const M_CUSTOMER_REFRESH_TOKEN = 'customerRefreshTokenComplete'
const M_CUSTOMER_RESET_PASSWORD = 'customerResetPasswordComplete'
const M_CUSTOMER_RESET_RESET_PASSWORD_STORE = 'customerResetResetPasswordStoreComplete'
const M_CUSTOMER_GET_RESET_PASSWORD_LINK = 'customerGetResetPasswordLinkComplete'
const M_CUSTOMER_RESET_GET_RESET_PASSWORD_LINK_STORE = 'customerResetGetResetPasswordLinkStoreComplete'
const M_CUSTOMER_VALIDATE_TOKEN = 'customerValidateTokenComplete'
const M_CUSTOMER_RESET_VALIDATE_TOKEN_STORE = 'customerResetValidateTokenStore'
const M_CUSTOMER_GET_INFORMATION = 'customerGetInformationComplete'
const M_CUSTOMER_UPDATE_INFORMATION = 'customerUpdateInformationComplete'
const M_CUSTOMER_RESET_UPDATE_INFORMATION_STORE = 'customerResetUpdateInformationStore'
const M_CUSTOMER_CHANGE_PASSWORD = 'customerChangePasswordComplete'
const M_CUSTOMER_RESET_CHANGE_PASSWORD_STORE = 'customerResetChangePasswordStore'
const M_CUSTOMER_ADD_ADDRESS = 'customerAddAddressComplete'
const M_CUSTOMER_RESET_ADD_ADDRESS_STORE = 'customerResetAddAddressStore'
const M_CUSTOMER_EDIT_ADDRESS = 'customerEditAddressComplete'
const M_CUSTOMER_RESET_EDIT_ADDRESS_STORE = 'customerResetEditAddressStore'

const state = {
  isLoggedIn: false,
  info: {
    uuid: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCode: '',
    phone: '',
    birthday: '',
    gender: undefined,
    idNumber: '',
    passportNumber: '',
    passportExpireDate: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    state: '',
    city: '',
    postcode: '',
    addresses: ''
  },
  registerResponse: {
    action: 'register',
    complete: false,
    success: false,
    code: 0
  },
  loginResponse: {
    action: 'login',
    complete: false,
    success: false,
    code: 0
  },
  resetPasswordResponse: {
    action: 'resetPassword',
    complete: false,
    success: false,
    code: 0
  },
  resetPasswordLinkResponse: {
    action: 'requestPasswordLink',
    complete: false,
    success: false,
    code: 0
  },
  logoutResponse: {
    action: 'logout',
    complete: false,
    success: false,
    code: 0,
    isSessionTimedOut: false
  },
  updateInformationResponse: {
    action: 'updateProfile',
    complete: false,
    success: false,
    code: 0
  },
  changePasswordResponse: {
    action: 'changePassword',
    complete: false,
    success: false,
    code: 0
  },
  addAddressResponse: {
    action: 'addAddress',
    complete: false,
    success: false,
    code: 0
  },
  editAddressResponse: {
    action: 'editAddress',
    complete: false,
    success: false,
    code: 0
  }
}

const getters = {}

const actions = {
  [A_CUSTOMER_REGISTER]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_REGISTER_STORE)
    sharedHelper.loading(dispatch)
    customerService
      .register(data)
      .then((result) => {
        commit(M_CUSTOMER_REGISTER, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'register' })
        commit(M_CUSTOMER_REGISTER, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_LOGIN]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_LOGIN_STORE)
    sharedHelper.loading(dispatch)
    customerService
      .login(data)
      .then((result) => {
        commit(M_CUSTOMER_LOGIN, { result, data })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'login' })
        commit(M_CUSTOMER_LOGIN, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_REFRESH_TOKEN]({ commit }, { data }) {
    customerService
      .refreshToken(data)
      .then((result) => {
        commit(M_CUSTOMER_REFRESH_TOKEN, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'refreshToken' })
        commit(M_CUSTOMER_REFRESH_TOKEN, { result })
      })
  },
  [A_CUSTOMER_LOGOUT]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_LOGOUT_STORE)
    sharedHelper.loading(dispatch)
    customerService
      .logout(data)
      .then((result) => {
        commit(M_CUSTOMER_LOGOUT, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'logout' })
        commit(M_CUSTOMER_LOGOUT, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_RESET_PASSWORD]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_RESET_PASSWORD_STORE)
    let purpose = data.purpose
    sharedHelper.loading(dispatch)
    customerService
      .resetPassword(data)
      .then((result) => {
        commit(M_CUSTOMER_RESET_PASSWORD, { result, purpose })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'resetPassword' })
        commit(M_CUSTOMER_RESET_PASSWORD, { result, purpose })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_GET_RESET_PASSWORD_LINK]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_GET_RESET_PASSWORD_LINK_STORE)
    sharedHelper.loading(dispatch)
    customerService
      .getResetPasswordLink(data)
      .then((result) => {
        commit(M_CUSTOMER_GET_RESET_PASSWORD_LINK, { result, data })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'resetPassword' })
        commit(M_CUSTOMER_GET_RESET_PASSWORD_LINK, { result, data })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_VALIDATE_TOKEN]({ dispatch, commit }, { requiredLoading }) {
    commit(M_CUSTOMER_RESET_VALIDATE_TOKEN_STORE)
    if (requiredLoading) {
      sharedHelper.loading(dispatch)
    }
    return customerService
      .validateToken()
      .then((result) => {
        commit(M_CUSTOMER_VALIDATE_TOKEN, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { undefined, result, action: 'validateToken' })
        commit(M_CUSTOMER_VALIDATE_TOKEN, { result })
      })
      .finally(() => {
        if (requiredLoading) {
          sharedHelper.unloading(dispatch)
        }
      })
  },
  async [A_CUSTOMER_GET_INFORMATION]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    await customerService
      .getInformation(data)
      .then((result) => {
        commit(M_CUSTOMER_GET_INFORMATION, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'getInformation' })
        commit(M_CUSTOMER_GET_INFORMATION, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_UPDATE_INFORMATION]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_UPDATE_INFORMATION_STORE)
    sharedHelper.loading(dispatch)
    customerService
      .updateInformation(data)
      .then((result) => {
        commit(M_CUSTOMER_UPDATE_INFORMATION, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'updateProfile' })
        commit(M_CUSTOMER_UPDATE_INFORMATION, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_CHANGE_PASSWORD]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_CHANGE_PASSWORD_STORE)
    sharedHelper.loading(dispatch)
    customerService
      .changePassword(data)
      .then((result) => {
        commit(M_CUSTOMER_CHANGE_PASSWORD, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'changePassword' })
        commit(M_CUSTOMER_CHANGE_PASSWORD, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_ADD_ADDRESS]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_ADD_ADDRESS_STORE)
    sharedHelper.loading(dispatch)
    customerService
      .addAddress(data)
      .then((result) => {
        commit(M_CUSTOMER_ADD_ADDRESS, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'addAddress' })
        commit(M_CUSTOMER_ADD_ADDRESS, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_EDIT_ADDRESS]({ commit, dispatch }, { data }) {
    commit(M_CUSTOMER_RESET_EDIT_ADDRESS_STORE)
    sharedHelper.loading(dispatch)
    customerService
      .updateAddress(data)
      .then((result) => {
        commit(M_CUSTOMER_EDIT_ADDRESS, { result })
      })
      .catch((result) => {
        commit(M_CUSTOMER_FAILURE, { data, result, action: 'editAddress' })
        commit(M_CUSTOMER_EDIT_ADDRESS, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_CUSTOMER_DELETE_ACCOUNT]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return customerService
      .deleteAccount(data)
      .then((result) => {
        const _buttons = [
          {
            title: localeHelper.getMessage('action.ok'),
            type: 'default',
            action: () => {
              dispatch(SHARED_CLOSE_DIALOG, {}, { root: true })
              commit(M_CUSTOMER_FAILURE, { data, result, action: 'logout' })
              commit(M_CUSTOMER_LOGOUT, { result })
              router.push({ name: ROUTE_NAME.HOME })
            }
          }
        ]
        dispatch(
          SHARED_SHOW_DIALOG,
          {
            messageTitle: localeHelper.getMessage('label.accountDeletedHeadline'),
            messages: [localeHelper.getErrorMessage(result.code)],
            messageErrorCode: result.code,
            buttons: _buttons
          },
          { root: true }
        )
      })
      .catch((result) => {
        const _buttons = [
          {
            title: localeHelper.getMessage('action.ok'),
            type: 'default',
            action: () => {
              dispatch(SHARED_CLOSE_DIALOG, {}, { root: true })
            }
          }
        ]
        dispatch(
          SHARED_SHOW_DIALOG,
          {
            messageTitle: localeHelper.getMessage('label.fail'),
            messages: [localeHelper.getMessage('label.deleteAccountPasswordIncorrect')],
            messageErrorCode: result.code,
            buttons: _buttons
          },
          { root: true }
        )
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  }
}

const mutations = {
  [M_CUSTOMER_FAILURE](state, { data, result, action }) {
    console.log(data)
    console.log(result)
    console.log(action)
    console.log('Development Call To Cloudwatch')
  },
  [M_CUSTOMER_REGISTER](state, { result }) {
    state.registerResponse = {
      action: result.success ? 'registerComplete' : 'register',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_CUSTOMER_RESET_REGISTER_STORE](state) {
    state.registerResponse = {
      action: 'register',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_CUSTOMER_LOGIN](state, { result, data }) {
    localStorage.removeItem(SESSION.CART)
    localStorage.removeItem(SESSION.CHECKOUT_CART)

    let d = result.data
    if (result.success) {
      if (d.token) {
        localStorage.setItem(SESSION.CUSTOMER_UUID, d.customer.uuid)
        localStorage.setItem(SESSION.EMAIL, d.customer.email)
        localStorage.setItem(SESSION.JWT_TOKEN, d.token.access_token)
        localStorage.setItem(SESSION.TOKEN_EXPIRY_TIME, new Date().setSeconds(d.token.expires_in))
        localStorage.setItem(SESSION.TOKEN_REFRESH_TIME, new Date().setSeconds(d.token.refresh_expires_in))
        state.info = {
          uuid: d.customer.uuid,
          firstName: d.customer.first_name,
          lastName: d.customer.last_name,
          email: d.customer.email,
          phoneCode: d.customer.phoneCode,
          phone: d.customer.phone
        }

        if (data.rememberMe) {
          const secretKey = KEY.RMBME_SECRET_KEY
          const username = data.email
          const password = data.password

          let encryptedUsername = aes256.encrypt(secretKey, username)
          let encryptedPassword = aes256.encrypt(secretKey, password)
          storageHelper.setLocalStorage(KEY.UN_KEY, encryptedUsername, 10080)
          storageHelper.setLocalStorage(KEY.PW_KEY, encryptedPassword, 10080)
        } else {
          storageHelper.removeLocalStorage(KEY.UN_KEY)
          storageHelper.removeLocalStorage(KEY.PW_KEY)
        }
        state.isLoggedIn = true
      }
    }

    state.loginResponse = {
      action: 'login',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_CUSTOMER_RESET_LOGIN_STORE](state) {
    state.loginResponse = {
      action: 'login',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_CUSTOMER_LOGOUT](state, { result }) {
    state.isLoggedIn = false
    localStorage.removeItem(SESSION.CUSTOMER_UUID)
    localStorage.removeItem(SESSION.EMAIL)
    localStorage.removeItem(SESSION.JWT_TOKEN)
    localStorage.removeItem(SESSION.TOKEN_EXPIRY_TIME)
    localStorage.removeItem(SESSION.TOKEN_REFRESH_TIME)

    if (result.success) {
      state.logoutResponse = {
        action: 'logout',
        complete: true,
        success: true,
        code: 0,
        isSessionTimedOut: false
      }
    } else {
      state.logoutResponse = {
        action: 'sessionTimedOut',
        complete: true,
        success: false,
        code: result.code,
        isSessionTimedOut: false
      }
    }
  },
  [M_CUSTOMER_RESET_LOGOUT_STORE](state) {
    state.logoutResponse = {
      action: 'logout',
      complete: false,
      success: false,
      code: 0,
      isSessionTimedOut: false
    }
  },
  [M_CUSTOMER_REFRESH_TOKEN](state, { result }) {
    let d = result.data
    if (result.success) {
      if (d.token) {
        localStorage.setItem(SESSION.JWT_TOKEN, d.token.access_token)
        localStorage.setItem(SESSION.TOKEN_EXPIRY_TIME, new Date().setSeconds(d.token.expires_in))
        localStorage.setItem(SESSION.TOKEN_REFRESH_TIME, new Date().setSeconds(d.token.refresh_expires_in))
      }
    } else {
      state.isLoggedIn = false
      localStorage.removeItem(SESSION.EMAIL)
      localStorage.removeItem(SESSION.JWT_TOKEN)
      localStorage.removeItem(SESSION.TOKEN_EXPIRY_TIME)
      localStorage.removeItem(SESSION.TOKEN_REFRESH_TIME)
      state.logoutResponse = {
        action: 'sessionTimedOut',
        complete: true,
        success: true,
        code: result.code,
        isSessionTimedOut: true
      }
    }
  },
  [M_CUSTOMER_RESET_PASSWORD](state, { result, purpose }) {
    let action = purpose == SHARED.RESET_PASSWORD_FIRST_TIME_LOGIN ? 'createPassword' : 'resetPassword'
    state.resetPasswordResponse = {
      action: action,
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_CUSTOMER_RESET_RESET_PASSWORD_STORE](state) {
    state.resetPasswordResponse = {
      action: 'resetPassword',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_CUSTOMER_GET_RESET_PASSWORD_LINK](state, { result, data }) {
    let action = data.purpose == SHARED.RESET_PASSWORD_FIRST_TIME_LOGIN ? 'requestPasswordLink' : 'forgotPassword'
    state.resetPasswordLinkResponse = {
      action: action,
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_CUSTOMER_RESET_GET_RESET_PASSWORD_LINK_STORE](state) {
    state.resetPasswordLinkResponse = {
      action: 'requestPasswordLink',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_CUSTOMER_VALIDATE_TOKEN](state, { result }) {
    state.isLoggedIn = false
    if (result.success) {
      let d = result.data
      state.info.email = d.username
      state.info.uuid = d.uuid
      state.isLoggedIn = true
    } else {
      state.isLoggedIn = false

      localStorage.removeItem(SESSION.EMAIL)
      localStorage.removeItem(SESSION.JWT_TOKEN)
      localStorage.removeItem(SESSION.TOKEN_EXPIRY_TIME)
      localStorage.removeItem(SESSION.TOKEN_REFRESH_TIME)

      state.logoutResponse = {
        action: 'sessionTimedOut',
        complete: true,
        success: true,
        code: result.code,
        isSessionTimedOut: true
      }
    }
  },
  [M_CUSTOMER_RESET_VALIDATE_TOKEN_STORE](state) {
    state.logoutResponse = {
      action: 'sessionTimedOut',
      complete: false,
      success: false,
      code: 0,
      isSessionTimedOut: false
    }
  },
  [M_CUSTOMER_GET_INFORMATION](state, { result }) {
    if (result.success) {
      let d = result.data
      state.info = {
        uuid: d.uuid,
        firstName: d.first_name,
        lastName: d.last_name,
        email: d.email,
        phoneCode: d.phone_code,
        phone: d.phone_no,
        birthday: d.birthday,
        gender: d.gender != null ? d.gender : null,
        idNumber: d.id_number,
        passportNumber: d.passport_number,
        passportExpireDate: d.passport_expire_date,
        addressOne: d.address_1,
        addressTwo: d.address_2,
        addressThree: d.address_3,
        state: d.state,
        city: d.city,
        postcode: d.postcode,
        addresses: d.addresses
      }
    }
  },
  [M_CUSTOMER_UPDATE_INFORMATION](state, { result }) {
    if (result.success) {
      let d = result.data
      state.info = {
        uuid: d.uuid,
        firstName: d.first_name,
        lastName: d.last_name,
        email: d.email,
        phoneCode: d.phone_code,
        phone: d.phone_no,
        birthday: d.birthday,
        gender: d.gender ? 1 : 0,
        idNumber: d.id_number,
        passportNumber: d.passport_number,
        passportExpireDate: d.passport_expire_date,
        addressOne: d.address_1,
        addressTwo: d.address_2,
        addressThree: d.address_3,
        state: d.state,
        city: d.city,
        postcode: d.postcode,
        addresses: d.addresses
      }
    }

    state.updateInformationResponse = {
      action: 'updateProfile',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_CUSTOMER_RESET_UPDATE_INFORMATION_STORE](state) {
    state.updateInformationResponse = {
      action: 'updateProfile',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_CUSTOMER_CHANGE_PASSWORD](state, { result }) {
    state.changePasswordResponse = {
      action: 'changePassword',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_CUSTOMER_RESET_CHANGE_PASSWORD_STORE](state) {
    state.changePasswordResponse = {
      action: 'changePassword',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_CUSTOMER_ADD_ADDRESS](state, { result }) {
    state.addAddressResponse = {
      action: 'addAddress',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_CUSTOMER_RESET_ADD_ADDRESS_STORE](state) {
    state.addAddressResponse = {
      action: 'addAddress',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_CUSTOMER_EDIT_ADDRESS](state, { result }) {
    state.editAddressResponse = {
      action: 'editAddress',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_CUSTOMER_RESET_EDIT_ADDRESS_STORE](state) {
    state.editAddressResponse = {
      action: 'editAddress',
      complete: false,
      success: false,
      code: 0
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
