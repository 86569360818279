<template>
  <v-card color="light_gray" elevation="0">
    <v-form ref="createPasswordForm">
      <v-row>
        <v-col scols="12">
          <div class="text-body-2 unbox_secondary--text">{{ $t(`label.createPasswordMessage`) }}</div>
        </v-col>

        <v-col cols="12" class="py-1">
          <app-form-field
            v-model.trim="createPasswordForm.password"
            :label="$t(`label.password`)"
            type="password"
            :placeholder="$t(`label.enterYourPassword`)"
            :rules="$validation.passwordRules()"
          ></app-form-field>
        </v-col>
        <v-col cols="12" class="py-1">
          <app-form-field
            v-model.trim="createPasswordForm.passwordConfirmation"
            :label="$t(`label.confirmPassword`)"
            type="password"
            :placeholder="$t(`label.enterConfirmPassword`)"
            :rules="$validation.confirmPasswordRules(createPasswordForm.password, createPasswordForm.passwordConfirmation)"
          ></app-form-field>
        </v-col>

        <v-col cols="12">
          <div class="text-body-2 unbox_secondary--text">{{ $t(`label.createPasswordRequirementMessage`) }}</div>
          <div>
            <ul>
              <li :class="createPasswordForm.password.length > 0 && createPasswordForm.password.length >= 8 ? 'success--text' : 'error--text'">
                <span class="text-body-2">{{ $t('validationMessage.passwordRulesMinimumLength') }}</span>
              </li>
              <li :class="createPasswordForm.password.length > 0 && createPasswordForm.password.length <= 20 ? 'success--text' : 'error--text'">
                <span class="text-body-2">{{ $t('validationMessage.passwordRulesMaximumLength') }}</span>
              </li>
              <li :class="regex.ATLEAST_ONE_UPPERCASE_LETTER.test(createPasswordForm.password) ? 'success--text' : 'error--text'">
                <span class="text-body-2">{{ $t('validationMessage.passwordRulesUppercaseLetter') }}</span>
              </li>
              <li :class="regex.ATLEAST_ONE_LOWERCASE_LETTER.test(createPasswordForm.password) ? 'success--text' : 'error--text'">
                <span class="text-body-2">{{ $t('validationMessage.passwordRulesLowercaseLetter') }}</span>
              </li>
              <li :class="regex.ATLEAST_ONE_DIGIT.test(createPasswordForm.password) ? 'success--text' : 'error--text'">
                <span class="text-body-2">{{ $t('validationMessage.passwordRulesDigit') }}</span>
              </li>
              <li :class="regex.ATLEAST_ONE_SPECIAL_CHARACTER.test(createPasswordForm.password) ? 'success--text' : 'error--text'">
                <span class="text-body-2">{{ $t('validationMessage.passwordRulesSpecialCharacter') }}</span>
              </li>
            </ul>
          </div>
        </v-col>

        <v-col cols="12">
          <div class="text-center">
            <v-btn
              class="full-width white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
              @click="submitResetPassword"
              height="56px"
              max-width="300px"
              color="unbox_black"
              elevation="0"
            >
              {{ resetButtonText }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { localeHelper } from '@/utils'
import { REGEX, SHARED } from '@/constants'
import { CUSTOMER_RESET_PASSWORD } from '@/store/customer.module'
export default {
  data: () => ({
    regex: REGEX,
    createPasswordForm: {
      token: '',
      password: '',
      passwordConfirmation: '',
      purpose: ''
    },
    resetButtonText: ''
  }),
  watch: {},
  created() {
    if (this.$root.$master.resetPasswordObj.purpose == SHARED.RESET_PASSWORD_FIRST_TIME_LOGIN) {
      this.resetButtonText = `${localeHelper.getMessage(`label.create`)} ${localeHelper.getMessage(`label.password`)}`
    } else {
      this.resetButtonText = `${localeHelper.getMessage(`label.reset`)} ${localeHelper.getMessage(`label.password`)}`
    }
  },
  methods: {
    submitResetPassword() {
      if (this.$refs.createPasswordForm.validate()) {
        let data = this.createPasswordForm
        data.token = this.$root.$master.resetPasswordObj.key
        data.purpose = this.$root.$master.resetPasswordObj.purpose
        this.$store.dispatch(CUSTOMER_RESET_PASSWORD, { data })
      }
    }
  }
}
</script>
