<template>
  <div>
    <app-header></app-header>
    <div class="body">
      <router-view></router-view>
    </div>
    <app-footer></app-footer>

    <!-- Registration & Login Dialog -->
    <app-dialog :dialogShow="registrationLoginDialog" :closeAction="() => (registrationLoginDialog = false)">
      <template v-slot:header>
        <div class="ma-auto">
          <v-tabs v-model="registrationLoginOpenTab" background-color="transparent" color="unbox_primary">
            <v-tab v-for="item in registrationLoginTab" :key="item.value" :ripple="false" :active-class="'font-weight-bold'" class="text-capitalize text-h6">
              {{ $t(`label.${item.text}`) }}
            </v-tab>
          </v-tabs>
        </div>
      </template>

      <template v-slot:body>
        <div>
          <v-tabs-items v-model="registrationLoginOpenTab">
            <v-tab-item>
              <div class="loginForm-tab">
                <app-login></app-login>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="registrationForm-tab">
                <app-registration></app-registration>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </app-dialog>

    <!-- FORGET PASSWORD FORM -->
    <app-dialog :dialogShow="forgotPasswordFormDialogShow" :closeAction="() => (forgotPasswordFormDialogShow = false)" :title="$t(`action.forgotPassword`)">
      <template v-slot:body>
        <div class="forgotPassswordForm-tab">
          <app-forgot-password></app-forgot-password>
        </div>
      </template>
    </app-dialog>

    <!-- FORGET PASSWORD FORM -->
    <app-dialog :dialogShow="firstTimeLoginFormDialogShow" :closeAction="() => (firstTimeLoginFormDialogShow = false)" :title="$t(`action.firstTimeLogin`)">
      <template v-slot:body>
        <div class="forgotPassswordForm-tab">
          <app-first-time-login></app-first-time-login>
        </div>
      </template>
    </app-dialog>

    <!-- RESET PASSWORD DIALOG -->
    <app-dialog
      :dialogShow="createPasswordDialogShow"
      :closeAction="this.pageDialog.dialogXButton"
      :title="resetPasswordObj.purpose == shared.RESET_PASSWORD_FIRST_TIME_LOGIN ? $t(`action.createPassword`) : $t(`action.forgotPassword`)"
    >
      <template v-slot:body>
        <div class="createPassswordForm-tab">
          <app-reset-password></app-reset-password>
        </div>
      </template>
    </app-dialog>

    <!-- CREATE PASSWORD LINK DIALOG -->
    <app-dialog :dialogShow="createPasswordLinkDialogShow" :title="$t(`action.requestPasswordLink`)">
      <template v-slot:body>
        <div class="createPassswordForm-tab">
          <app-create-password-link></app-create-password-link>
        </div>
      </template>
    </app-dialog>

    <!-- ADD ADDRESS DIALOG -->
    <app-dialog
      :dialogShow="deliveryAddressDialogShow"
      :title="deliveryAddressObj.isEdit ? $t(`action.editAddress`) : $t(`action.addAddress`)"
      :closeAction="() => (deliveryAddressDialogShow = !deliveryAddressDialogShow)"
    >
      <template v-slot:body>
        <div class="deliveryAddressFrom-tab">
          <app-delivery-address></app-delivery-address>
        </div>
      </template>
    </app-dialog>

    <!-- Message Dialog -->
    <app-dialog :dialogShow="pageDialogShow" :title="pageDialog.title" :closeAction="this.pageDialog.dialogXButton">
      <template v-slot:body>
        <div class="pageMessage-tab">
          <app-page-dialog
            :dialogMessageTitle="pageDialog.messageTitle"
            :dialogMessage="pageDialog.message"
            :dialogButton="pageDialog.button"
            :dialogErrorCode="pageDialog.errorCode"
          ></app-page-dialog>
        </div>
      </template>
    </app-dialog>

    <!-- <app-loading-progress ref="loading"></app-loading-progress> -->

    <v-snackbar centered color="unbox_primary" v-model="snackBarShow" :timeout="1500">
      {{ snackBarMessage }}
    </v-snackbar>

    <!-- Message Dialog -->
    <app-dialog :dialog-show="messageDialogDisplay">
      <template v-slot:body>
        <div class="pageMessage-tab">
          <div class="text-center">
            <dialog-exclamation v-if="messageDialog.type === 'error'" />
            <dialog-tick v-if="messageDialog.type === 'success'" />
          </div>
          <app-page-dialog
            :dialogMessage="messageDialog.messages"
            :dialogMessageTitle="messageDialog.messageTitle"
            :dialogButton="messageDialog.buttons"
            :dialogErrorCode="messageDialog.messageErrorCode"
          ></app-page-dialog>
        </div>
      </template>
    </app-dialog>
  </div>
</template>
<script>
import AppHeader from '@/components/layout/header'
import AppFooter from '@/components/layout/footer'
import AppLogin from '@/components/member/login'
import AppRegistration from '@/components/member/registration'
import AppResetPassword from '@/components/member/resetPassword'
import AppCreatePasswordLink from '@/components/member/createPasswordLink'
import AppForgotPassword from '@/components/member/forgotPassword'
import AppFirstTimeLogin from '@/components/member/firstTimeLogin'
import AppDeliveryAddress from '@/components/member/deliveryAddress'
import AppLoadingProgress from '@/components/layout/loadingProgress'
import AppPageDialog from '@/components/dialog/pageDialog.vue'
import { ACTION, SESSION, SHARED } from '@/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import { localeHelper, sharedHelper } from '@/utils'
import ExclamationMark from '@/assets/icons/exclamationMark'
import DialogExclamation from '@/assets/icons/dialogExclamation'
import DialogTick from '@/assets/icons/dialogTick'
import { CUSTOMER_VALIDATE_TOKEN, CUSTOMER_REFRESH_TOKEN, CUSTOMER_LOGOUT, CUSTOMER_GET_INFORMATION } from '@/store/customer.module'
import { ORDER_GET_CART } from '@/store/order.module'
import { PRODUCT_TOTAL_CART_PRICE } from '@/store/product.module'
import { STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART } from '@/store/stock.module'
import { LOOKUPS_CART_STATUSES, LOOKUP_CART_STATUS_UUID_BY_NAME } from '@/store/lookup.module'

export default {
  name: 'AppLayout',
  components: {
    DialogTick,
    DialogExclamation,
    ExclamationMark,
    AppHeader,
    AppFooter,
    AppLogin,
    AppRegistration,
    AppResetPassword,
    AppCreatePasswordLink,
    AppForgotPassword,
    AppDeliveryAddress,
    AppLoadingProgress,
    AppPageDialog,
    AppFirstTimeLogin
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.customer.isLoggedIn
    },
    registerResponseComplete() {
      return this.$store.state.customer.registerResponse.complete
    },
    loginResponseComplete() {
      return this.$store.state.customer.loginResponse.complete
    },
    resetPasswordResponseComplete() {
      return this.$store.state.customer.resetPasswordResponse.complete
    },
    resetPasswordLinkResponseComplete() {
      return this.$store.state.customer.resetPasswordLinkResponse.complete
    },
    logoutResponseComplete() {
      return this.$store.state.customer.logoutResponse.complete
    },
    messageDialog() {
      return this.$store.state.shared.messageDialog
    },
    messageDialogDisplay() {
      return !!this.$store.state.shared.messageDialogDisplay
    },
    cartItems() {
      return this.$store.state.order.cart.items
    },
    alreadyHaveAllStockData() {
      const cachedStockSkus = this.$store.state.stock.cartStock.map((i) => i.display_sku)
      const cartItemSkus = this.cartItems.map((i) => i.productDisplaySku)
      return cartItemSkus.every((sku) => cachedStockSkus.includes(sku))
    }
  },
  data: () => ({
    searchKeyword: '',
    snackBarShow: false,
    snackBarMessage: '',
    shared: SHARED,
    pageDialogShow: false,
    createPasswordDialogShow: false,
    createPasswordLinkDialogShow: false,
    forgotPasswordFormDialogShow: false,
    firstTimeLoginFormDialogShow: false,
    deliveryAddressDialogShow: false,
    deliveryAddressObj: {
      isEdit: false,
      isFirstAddress: false,
      address: {}
    },
    resetPasswordObj: {
      key: '',
      purpose: ''
    },
    pageDialog: {
      title: '',
      closePageDialog: () => {},
      messageTitle: '',
      message: [],
      button: [],
      errorCode: ''
    },
    registrationLoginTab: [
      { value: 0, text: 'signIn' },
      {
        value: 1,
        text: 'signUp'
      }
    ],
    registrationLoginOpenTab: ACTION.LOGIN,
    registrationLoginDialog: false
  }),
  watch: {
    registerResponseComplete() {
      let response = this.$store.state.customer.registerResponse

      if (response.complete) {
        this.responseCompleteDialog(response, localeHelper.getMessage(`message.registerComplete`))
        if (response.success) this.registrationLoginDialog = false
      }
    },
    loginResponseComplete() {
      let response = this.$store.state.customer.loginResponse

      if (response.complete) {
        if (!response.success) this.responseCompleteDialog(response)
        else {
          this.registrationLoginDialog = false
          this.updateCartInfo()
          this.memberTokenRoutineChecker()
        }
      }
    },
    resetPasswordResponseComplete() {
      let response = this.$store.state.customer.resetPasswordResponse

      if (response.complete) {
        if (!response.success) {
          let buttons = []

          if (response.code == '20.1.011') {
            buttons.push({
              title: localeHelper.getMessage('label.resendLink'),
              action: this.openResendCreatePasswordLinkDialog
            })
          }
          this.responseCompleteDialog(response, '', buttons)
        } else {
          this.createPasswordDialogShow = false
          this.responseCompleteDialog(response, localeHelper.getMessage('message.createPasswordComplete'))
        }
      }
    },
    resetPasswordLinkResponseComplete() {
      let response = this.$store.state.customer.resetPasswordLinkResponse

      if (response.complete) {
        if (!response.success) {
          this.responseCompleteDialog(response)
        } else {
          this.createPasswordLinkDialogShow = false
          this.forgotPasswordFormDialogShow = false
          this.firstTimeLoginFormDialogShow = false
          this.responseCompleteDialog(response, localeHelper.getMessage(`message.requestNewPasswordLinkComplete`))
        }
      }
    },
    logoutResponseComplete() {
      let requiredAuthentication = this.$route.meta.requiredAuthentication
      let response = this.$store.state.customer.logoutResponse

      if (response.complete) {
        if (requiredAuthentication) {
          this.$router.push({
            name: ROUTE_NAME.HOME
          })
        }
        clearInterval(this.$options.validateMemberToken)
      }
    }
  },
  created() {
    this.$root.$master = this
    this.memberTokenRoutineChecker()
    this.updateCartInfo()
    this.setEscEvent()
  },
  methods: {
    setEscEvent() {
      let root = this.$root.$master
      document.addEventListener('keydown', function (evt) {
        if (evt.keyCode === 27 && root.registrationLoginDialog) {
          root.registrationLoginDialog = false
        }
      })
    },
    showSnackbarMessage(message) {
      this.snackBarMessage = message
      this.snackBarShow = true
    },
    async updateCartInfo() {
      if (localStorage.getItem(SESSION.JWT_TOKEN)) {
        await this.$store.dispatch(LOOKUPS_CART_STATUSES)
        // to get cart items (no prices)
        await this.$store.dispatch(ORDER_GET_CART, { data: { page: 1, size: 10, cartStatusesUuid: this.$store.getters[LOOKUP_CART_STATUS_UUID_BY_NAME]('pending') } })
        // to get cart item prices and calculate total cart amount
        await this.$store.dispatch(PRODUCT_TOTAL_CART_PRICE, { products: this.cartItems })
        // to get available stock quantities for the items in cart
        await this.getCartStockInformation()
      }
    },
    getCartStockInformation() {
      if (this.alreadyHaveAllStockData) return
      return Promise.all(
        this.cartItems.map((item) => {
          let data = {
            displaySku: item.productDisplaySku
          }
          return this.$store.dispatch(STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART, { data })
        })
      )
    },
    async memberTokenRoutineChecker() {
      if (localStorage.getItem(SESSION.JWT_TOKEN)) {
        let requiredLoading = true
        let data = {
          uuid: localStorage.getItem(SESSION.CUSTOMER_UUID)
        }
        await this.$store.dispatch(CUSTOMER_VALIDATE_TOKEN, { requiredLoading })
        this.$store.dispatch(CUSTOMER_GET_INFORMATION, { data })
        this.$options.validateMemberToken = setInterval(this.validateMemberToken, 20000)
      }
    },
    validateMemberToken() {
      if (new Date(parseInt(localStorage.getItem(SESSION.TOKEN_EXPIRY_TIME))) - new Date() < 60000) {
        if (new Date() - new Date(parseInt(localStorage.getItem(SESSION.LAST_ACTIVITY_TIME))) > 10800000) {
          const logoutObj = {}
          let sessionTimedOut = true
          this.$store.dispatch(CUSTOMER_LOGOUT, { logoutObj, sessionTimedOut })
        } else {
          let data = {
            platform: sharedHelper.getPlatform(),
            domain: sharedHelper.getHostname()
          }
          this.$store.dispatch(CUSTOMER_REFRESH_TOKEN, { data })
        }
      } else {
        let requiredLoading = false
        this.$store.dispatch(CUSTOMER_VALIDATE_TOKEN, { requiredLoading })
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: localeHelper.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: [],
        errorCode: ''
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.pageDialog = dialog
      this.pageDialogShow = true
    },
    closePageDialog() {
      this.pageDialogShow = false
      this.initializePageDialogProperty()
    },
    openRegistration() {
      this.registrationLoginOpenTab = ACTION.REGISTER
      this.registrationLoginDialog = true
    },
    openLogin() {
      this.registrationLoginOpenTab = ACTION.LOGIN
      this.registrationLoginDialog = true
    },
    logout() {
      let data = {
        platform: sharedHelper.getPlatform(),
        domain: sharedHelper.getHostname()
      }
      this.$store.dispatch(CUSTOMER_LOGOUT, { data })
    },
    responseCompleteDialog(response, successMessage = '', buttons = []) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = localeHelper.getMessage(`action.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(localeHelper.getErrorMessage(`${response.code}`))
        dialog.errorCode = response.code
      } else {
        dialog.message.push(successMessage)
      }

      if (buttons.length > 0) {
        buttons.forEach((x) => {
          dialog.button.push({
            title: x.title,
            action: x.action
          })
        })
      }
      dialog.button.push({
        title: localeHelper.getMessage(`label.close`),
        action: this.closePageDialog,
        type: buttons.length > 0 ? 'secondary' : 'default'
      })
      this.openPageDialog(dialog)
    },
    /* USED FOR FORGOT PASSWORD AND FIRST TIME LOGIN */
    openResetPasswordDialog(key, purpose) {
      this.resetPasswordObj.purpose = purpose
      this.resetPasswordObj.key = key
      this.createPasswordDialogShow = true
    },
    openResendCreatePasswordLinkDialog() {
      this.createPasswordDialogShow = false
      this.closePageDialog()
      this.createPasswordLinkDialogShow = true
    },
    openForgotPasswordFormDialog() {
      this.registrationLoginDialog = false
      this.forgotPasswordFormDialogShow = true
    },
    openFirstTimeLoginFormDialog() {
      this.registrationLoginDialog = false
      this.firstTimeLoginFormDialogShow = true
    }
  }
}
</script>
<style lang="scss">
.loginForm-tab,
.createPassswordForm-tab,
.forgotPassswordForm-tab {
  width: 100%;
  max-width: 300px;
}

.changeAddressFrom-tab {
  width: 400px;
}
.deliveryAddressFrom-tab {
  width: 100%;
  max-width: 650px;
}

.pageMessage-tab {
  width: 100%;
  max-width: 324px;
}
.registrationForm-tab {
  width: 650px;
}

@media only screen and (max-width: 959px) {
  .registrationForm-tab,
  .changeAddressFrom-tab {
    width: 100%;
    max-width: 300px;
  }
}
</style>
