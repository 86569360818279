import { stockService } from '@/services'
import { sharedHelper } from '@/utils'
import { SHARED } from '@/constants'
const MODULE_NAME = 'stock/'

const A_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU = 'stockOutletQuantityByDisplaySku'
const A_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART = 'stockOutletQuantityByDisplaySkuForCart'
const A_STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE = 'stockResetOutletQuantityByDisplaySkuForCartStore'

export const STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU = MODULE_NAME + A_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU
export const STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART = MODULE_NAME + A_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART
export const STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE = MODULE_NAME + A_STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE

const M_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU = 'setStockOutletQuantityByDisplaySku'
const M_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART = 'setStockOutletQuantityByDisplaySkuForCart'
const M_STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE = 'stockResetOutletQuantityByDisplaySkuForCartStoreComplete'

const state = {
  stock: null,
  cartStock: []
}

const getters = {}

const actions = {
  [A_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU]({ commit, dispatch }, { data }) {
    let info = data
    stockService
      .getStockLevelOnOutlet(data)
      .then((data) => {
        commit(M_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU, { data, info })
      })
      .catch((err) => {
        commit(M_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU, { err })
      })
      .finally(() => {})
  },
  [A_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART]({ commit, dispatch }, { data }) {
    let info = data
    return stockService
      .getStockLevelOnOutlet(data)
      .then((data) => {
        commit(M_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART, { data, info })
      })
      .catch((err) => {
        commit(M_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART, { err })
      })
      .finally(() => {})
  },
  [A_STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE]({ commit }) {
    commit(M_STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE)
  }
}

const mutations = {
  [M_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU](state, { data, info }) {
    let d = data.data

    if (d.length <= 0) {
      state.stock = {
        quantity: 0,
        display_sku: info.displaySku
      }
    } else {
      state.stock = d[0]
    }
  },
  [M_STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART](state, { data, info }) {
    let d = data.data
    let stockData = null
    if (d.length <= 0) {
      stockData = {
        quantity: 0,
        display_sku: info.displaySku
      }
    } else {
      stockData = d[0]
    }

    let cartStock = state.cartStock.find((y) => y.display_sku == stockData.display_sku)
    if (cartStock != undefined) {
      cartStock.quantity = stockData.quantity
    } else {
      state.cartStock.push(stockData)
    }
  },
  [M_STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE](state) {
    state.cartStock = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
