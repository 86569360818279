import { API } from '@/constants'
import { apiHelper } from '@/utils'

// v1 suffix is not required for loan service for now
const API_URL = API.BASE_URL.replace('@serviceName', 'loan')

const LOAN = {
  CLASS: 'class',
  LOOKUPS: 'lookups',
  INCOME_RANGES: 'income-ranges',
  CREDIT_CARDS: 'credit-cards',
  CREDIT_CARD: 'credit-card',
  TAC: 'tac',
  SUBMIT: 'submit',
  PERSONAL_LOANS: 'personal-loans',
  PERSONAL_LOAN: 'personal-loan',
  CHECK_PROMO_CODE: 'promotion-codes'
}

export function registerClass(data) {
  let requestOptions = {
    name: data.name,
    email: data.email,
    contact_no: data.contactNumber,
    event_info: JSON.stringify(data.eventInfo)
  }
  return apiHelper(API_URL, true).post(`/${LOAN.CLASS}`, requestOptions)
}

export function getIncomeRangesLookup() {
  return apiHelper(API_URL, false).get(`/${LOAN.LOOKUPS}/${LOAN.INCOME_RANGES}`)
}

export function getCreditCardsLookup() {
  return apiHelper(API_URL, false).get(`/${LOAN.LOOKUPS}/${LOAN.CREDIT_CARDS}`)
}

export function getPersonalLoansLookup() {
  return apiHelper(API_URL, false).get(`/${LOAN.LOOKUPS}/${LOAN.PERSONAL_LOANS}`)
}

export function requestTac(data) {
  let requestOptions = {
    phone_code: data.phoneCode,
    phone_no: data.phoneNo
  }
  return apiHelper(API_URL, false).post(`/${LOAN.CREDIT_CARD}/${LOAN.TAC}`, requestOptions)
}

export function submitCreditCardApplication(data) {
  let requestOptions = {
    pin: data.tacNumber,
    credit_card_uuid: data.creditCardUuid,
    income_range_uuid: data.incomeRangeUuid,
    full_name: data.name,
    nric: data.nric,
    phone_code: data.phoneCode,
    phone_no: data.phoneNo,
    email: data.email
  }
  return apiHelper(API_URL, false).post(`/${LOAN.CREDIT_CARD}/${LOAN.SUBMIT}`, requestOptions)
}

export function submitPersonalLoanApplication(data) {
  let requestOptions = {
    pin: data.tacNumber,
    personal_loan_uuid: data.personalLoanUuid,
    income_range_uuid: data.incomeRangeUuid,
    full_name: data.name,
    nric: data.nric,
    phone_code: data.phoneCode,
    phone_no: data.phoneNo,
    email: data.email,
    loan_amount: data.loanAmount,
    loan_tenure_year: data.loanTenureYear
  }
  return apiHelper(API_URL, false).post(`/${LOAN.PERSONAL_LOAN}/${LOAN.SUBMIT}`, requestOptions)
}

export function getPromoCodeInfo(data) {
  const requestOptions = {
    params: {
      promotion_code: data.promoCode
    }
  }
  return apiHelper(API_URL, false).get(`/${LOAN.CHECK_PROMO_CODE}`, requestOptions)
}
