<template>
  <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.770508" y="0.5" width="110" height="110" rx="55" fill="#6EE7B7" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.6486 66.0604L79.5852 34.3457L84.4454 39.1721L47.6486 75.7132L28.0352 56.2361L32.8953 51.4097L47.6486 66.0604Z" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'dialogTick'
}
</script>
