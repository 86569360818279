import { loanService } from '@/services'
import { localeHelper, sharedHelper } from '@/utils'
import { SHARED_CLOSE_DIALOG, SHARED_SHOW_DIALOG } from './shared.module'
const MODULE_NAME = 'loan/'

const A_LOAN_REGISTER_CLASS = 'loanRegisterClass'
const A_LOAN_CREDIT_CARD_REQUEST_TAC = 'loanCreditCardRequestTac'
const A_LOAN_CREDIT_CARD_APPLICATION_SUBMIT = 'loanCreditCardRequestApplicationSubmit'
const A_LOAN_PERSONAL_LOAN_REQUEST_TAC = 'loanPersonalLoanRequestTac'
const A_LOAN_PERSONAL_LOAN_APPLICATION_SUBMIT = 'loanPersonalLoanRequestApplicationSubmit'
const A_LOAN_CHECK_PROMO_CODE = 'loanCheckPromoCode'

export const LOAN_REGISTER_CLASS = MODULE_NAME + A_LOAN_REGISTER_CLASS
export const LOAN_CREDIT_CARD_REQUEST_TAC = MODULE_NAME + A_LOAN_CREDIT_CARD_REQUEST_TAC
export const LOAN_CREDIT_CARD_APPLICATION_SUBMIT = MODULE_NAME + A_LOAN_CREDIT_CARD_APPLICATION_SUBMIT
export const LOAN_PERSONAL_LOAN_REQUEST_TAC = MODULE_NAME + A_LOAN_PERSONAL_LOAN_REQUEST_TAC
export const LOAN_PERSONAL_LOAN_APPLICATION_SUBMIT = MODULE_NAME + A_LOAN_PERSONAL_LOAN_APPLICATION_SUBMIT
export const LOAN_CHECK_PROMO_CODE = MODULE_NAME + A_LOAN_CHECK_PROMO_CODE

const M_LOAN_CREDIT_CARD_REQUEST_TAC = 'loanCreditCardRequestTacComplete'
const M_LOAN_RESET_CREDIT_CARD_REQUEST_TAC = 'loanResetCreditCardRequestTacComplete'
const M_LOAN_CREDIT_CARD_APPLICATION_SUBMIT = 'loanCreditCardRequestApplicationSubmitComplete'
const M_LOAN_RESET_CREDIT_CARD_APPLICATION_SUBMIT = 'loanResetCreditCardRequestApplicationSubmitComplete'
const M_LOAN_PERSONAL_LOAN_REQUEST_TAC = 'loanPersonalLoanRequestTacComplete'
const M_LOAN_RESET_PERSONAL_LOAN_REQUEST_TAC = 'loanResetPersonalLoanRequestTacComplete'
const M_LOAN_PERSONAL_LOAN_APPLICATION_SUBMIT = 'loanPersonalLoanRequestApplicationSubmitComplete'
const M_LOAN_RESET_PERSONAL_LOAN_APPLICATION_SUBMIT = 'loanResetPersonalLoanRequestApplicationSubmitComplete'
const M_LOAN_CHECK_PROMO_CODE = 'loanCheckPromoCodeComplete'

const state = {
  loanCreditCardRequestTacResponse: {
    complete: false,
    success: false,
    code: 0
  },
  loanCreditCardApplicationSubmitResponse: {
    complete: false,
    success: false,
    code: 0
  },
  loanPersonalLoanRequestTacResponse: {
    complete: false,
    success: false,
    code: 0
  },
  loanPersonalLoanApplicationSubmitResponse: {
    complete: false,
    success: false,
    code: 0
  },
  promoCodeData: {
    code: '',
    startDate: '',
    endDate: '',
    amount: 0,
    minSpendAmount: 0,
    maxRedeemableNo: 0
  }
}

const getters = {}

const actions = {
  [A_LOAN_REGISTER_CLASS]({ dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return loanService
      .registerClass(data)
      .then((result) => {
        return result
      })
      .catch((error) => {
        return error
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOAN_CREDIT_CARD_REQUEST_TAC]({ commit, dispatch }, { data }) {
    commit(M_LOAN_RESET_CREDIT_CARD_REQUEST_TAC)
    sharedHelper.loading(dispatch)
    loanService
      .requestTac(data)
      .then((result) => {
        commit(M_LOAN_CREDIT_CARD_REQUEST_TAC, { result })
      })
      .catch((result) => {
        commit(M_LOAN_CREDIT_CARD_REQUEST_TAC, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOAN_CREDIT_CARD_APPLICATION_SUBMIT]({ commit, dispatch }, { data }) {
    commit(M_LOAN_RESET_CREDIT_CARD_APPLICATION_SUBMIT)
    sharedHelper.loading(dispatch)
    loanService
      .submitCreditCardApplication(data)
      .then((result) => {
        commit(M_LOAN_CREDIT_CARD_APPLICATION_SUBMIT, { result })
      })
      .catch((result) => {
        commit(M_LOAN_CREDIT_CARD_APPLICATION_SUBMIT, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOAN_PERSONAL_LOAN_REQUEST_TAC]({ commit, dispatch }, { data }) {
    commit(M_LOAN_RESET_PERSONAL_LOAN_REQUEST_TAC)
    sharedHelper.loading(dispatch)
    loanService
      .requestTac(data)
      .then((result) => {
        commit(M_LOAN_PERSONAL_LOAN_REQUEST_TAC, { result })
      })
      .catch((result) => {
        commit(M_LOAN_PERSONAL_LOAN_REQUEST_TAC, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOAN_PERSONAL_LOAN_APPLICATION_SUBMIT]({ commit, dispatch }, { data }) {
    commit(M_LOAN_RESET_PERSONAL_LOAN_APPLICATION_SUBMIT)
    sharedHelper.loading(dispatch)
    loanService
      .submitPersonalLoanApplication(data)
      .then((result) => {
        commit(M_LOAN_PERSONAL_LOAN_APPLICATION_SUBMIT, { result })
      })
      .catch((result) => {
        commit(M_LOAN_PERSONAL_LOAN_APPLICATION_SUBMIT, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_LOAN_CHECK_PROMO_CODE]({ commit, dispatch }, data) {
    sharedHelper.loading(dispatch)
    return loanService
      .getPromoCodeInfo(data)
      .then((result) => {
        commit(M_LOAN_CHECK_PROMO_CODE, { result })
      })
      .catch((result) => {
        commit(M_LOAN_CHECK_PROMO_CODE, { result })

        const _buttons = [
          {
            title: localeHelper.getMessage('action.ok'),
            type: 'default',
            action: () => {
              dispatch(SHARED_CLOSE_DIALOG, {}, { root: true })
            }
          }
        ]
        dispatch(
          SHARED_SHOW_DIALOG,
          {
            messageTitle: localeHelper.getMessage('label.invalidPromoCode'),
            messages: [localeHelper.getErrorMessage(result.code)],
            messageErrorCode: result.code,
            buttons: _buttons
          },
          { root: true }
        )
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  }
}

const mutations = {
  [M_LOAN_CREDIT_CARD_REQUEST_TAC](state, { result }) {
    state.loanCreditCardRequestTacResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_LOAN_RESET_CREDIT_CARD_REQUEST_TAC](state) {
    state.loanCreditCardRequestTacResponse = {
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_LOAN_CREDIT_CARD_APPLICATION_SUBMIT](state, { result }) {
    state.loanCreditCardApplicationSubmitResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_LOAN_RESET_CREDIT_CARD_APPLICATION_SUBMIT](state) {
    state.loanCreditCardApplicationSubmitResponse = {
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_LOAN_PERSONAL_LOAN_REQUEST_TAC](state, { result }) {
    state.loanPersonalLoanRequestTacResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_LOAN_RESET_PERSONAL_LOAN_REQUEST_TAC](state) {
    state.loanPersonalLoanRequestTacResponse = {
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_LOAN_PERSONAL_LOAN_APPLICATION_SUBMIT](state, { result }) {
    state.loanPersonalLoanApplicationSubmitResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_LOAN_RESET_PERSONAL_LOAN_APPLICATION_SUBMIT](state) {
    state.loanPersonalLoanApplicationSubmitResponse = {
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_LOAN_CHECK_PROMO_CODE](state, { result }) {
    if (result.success) {
      const d = result.data
      state.promoCodeData = {
        code: d.code,
        startDate: d.start_date,
        endDate: d.end_date,
        amount: d.amount,
        minSpendAmount: d.min_spend_amount,
        maxRedeemableNo: d.max_redeemable_no
      }
    } else {
      state.promoCodeData = { code: '', startDate: '', endDate: '', amount: 0, minSpendAmount: 0, maxRedeemableNo: 0 }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
