<template>
  <v-card color="light_gray" elevation="0">
    <v-form ref="deliveryAddressForm">
      <v-row>
        <v-col cols="12" class="pa-0 ma-0"></v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field v-model.trim="deliveryAddressForm.name" :label="$t(`label.recipientName`)" :placeholder="$t(`label.name`)" :rules="$validation.nameRules()"></app-form-field>
        </v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field
            :prefix="deliveryAddressForm.phoneCode"
            v-model.trim="deliveryAddressForm.phone"
            :label="$t(`label.phone`)"
            :placeholder="$t(`label.phone`)"
            :rules="$validation.phoneRules()"
          ></app-form-field>
        </v-col>

        <v-col cols="12" class="py-1">
          <app-form-field v-model.trim="deliveryAddressForm.address" :label="$t(`label.address`)" :placeholder="$t(`label.streetBulidngName`)" :rules="$validation.addressRules()"></app-form-field>
        </v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field v-model.trim="deliveryAddressForm.block" isOptional :label="$t(`label.block`)" :placeholder="$t(`label.block`)" :rules="$validation.addressBlockRules()"></app-form-field>
        </v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field
            v-model.trim="deliveryAddressForm.postcode"
            v-mask="'#####'"
            :label="$t(`label.postcode`)"
            :placeholder="$t(`label.postcode`)"
            :rules="$validation.postcodeRules()"
          ></app-form-field>
        </v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field v-model.trim="deliveryAddressForm.city" :label="$t(`label.city`)" :placeholder="$t(`label.city`)" :rules="$validation.requiredRules()"></app-form-field>
        </v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field
            v-model.trim="deliveryAddressForm.state"
            :label="$t(`label.state`)"
            type="select"
            :items="states"
            :placeholder="$t(`label.state`)"
            :rules="$validation.dropDownListRules()"
          ></app-form-field>
        </v-col>
        <v-col cols="12" class="py-1">
          <v-switch v-model="deliveryAddressForm.defaultShippingAddress" :readonly="isFirstAddress" dense flat hide-details class="mt-0 mb-2" :label="$t(`label.defaultShippingAddress`)"></v-switch>
          <v-switch v-model="deliveryAddressForm.defaultBillingAddress" :readonly="isFirstAddress" dense flat hide-details class="mt-0 mb-2" :label="$t(`label.defaultBillingAddress`)"></v-switch>
        </v-col>
        <v-col cols="12">
          <div class="text-center">
            <v-btn
              class="full-width white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
              @click="submitDeliveryAddress"
              height="56px"
              max-width="300px"
              color="unbox_black"
              elevation="0"
            >
              {{ $t(`label.save`) }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import mp from 'malaysia-postcodes'
import { ddlHelper } from '@/utils'
import { CUSTOMER_ADD_ADDRESS, CUSTOMER_EDIT_ADDRESS } from '@/store/customer.module'
import { SESSION } from '@/constants'
export default {
  data: () => ({
    states: ddlHelper.getCountryStates(),
    cities: [],
    deliveryAddressForm: {
      uuid: localStorage.getItem(SESSION.CUSTOMER_UUID),
      name: '',
      phoneCode: '+60',
      phone: '',
      block: '',
      address: '',
      postcode: '',
      city: '',
      state: '',
      defaultShippingAddress: false,
      defaultBillingAddress: false
    },
    isEdit: false,
    isFirstAddress: false
  }),
  created() {
    this.initDeliveryAddress()
  },
  watch: {
    'deliveryAddressForm.postcode'(postCode) {
      let postCodeInfo = mp.findPostcode(postCode)
      if (postCodeInfo.found) {
        let city = postCodeInfo.city
        let state = postCodeInfo.state

        switch (state) {
          case 'Melaka':
            state = 'Malacca'
            break
          case 'Wp Kuala Lumpur':
            state = 'Kuala Lumpur'
            break
          case 'Wp Labuan':
            state = 'Labuan'
            break
          case 'Wp Putrajaya':
            state = 'Putrajaya'
            break
        }

        this.deliveryAddressForm.state = state
        this.deliveryAddressForm.city = this.deliveryAddressForm.city || city // || is to prevent overriding custom city (e.g. Serdang is not in city dropdown)
      }
    },
    'deliveryAddressForm.state'(state) {
      let isoCode = this.states.find((x) => x.text == state).isoCode
      this.cities = ddlHelper.getCitiesOfState(isoCode)
    }
  },
  methods: {
    initDeliveryAddress() {
      this.isEdit = this.$root.$master.deliveryAddressObj.isEdit
      this.isFirstAddress = this.$root.$master.deliveryAddressObj.isFirstAddress
      if (this.isFirstAddress) {
        this.deliveryAddressForm.defaultShippingAddress = true
        this.deliveryAddressForm.defaultBillingAddress = true
      }

      if (this.$root.$master.deliveryAddressObj.isEdit) {
        this.deliveryAddressForm.name = this.$root.$master.deliveryAddressObj.address.display_name ?? ''
        this.deliveryAddressForm.phoneCode = this.$root.$master.deliveryAddressObj.address.phone_code ?? '+60'
        this.deliveryAddressForm.phone = this.$root.$master.deliveryAddressObj.address.phone_no ?? ''
        this.deliveryAddressForm.block = this.$root.$master.deliveryAddressObj.address.block ?? ''
        this.deliveryAddressForm.address = `${this.$root.$master.deliveryAddressObj.address.address_1 ?? ''} ${this.$root.$master.deliveryAddressObj.address.address_2 ?? ''} ${
          this.$root.$master.deliveryAddressObj.address.address_3 ?? ''
        }`.trim()
        this.deliveryAddressForm.postcode = this.$root.$master.deliveryAddressObj.address.postcode ?? ''
        this.deliveryAddressForm.city = this.$root.$master.deliveryAddressObj.address.city ?? ''
        this.deliveryAddressForm.state = this.$root.$master.deliveryAddressObj.address.state ?? ''
        this.deliveryAddressForm.defaultShippingAddress = this.$root.$master.deliveryAddressObj.address.is_default_shipping
        this.deliveryAddressForm.defaultBillingAddress = this.$root.$master.deliveryAddressObj.address.is_default_billing
        this.deliveryAddressForm.addressUuid = this.$root.$master.deliveryAddressObj.address.uuid
      }
    },
    submitDeliveryAddress() {
      if (this.$refs.deliveryAddressForm.validate()) {
        let data = this.deliveryAddressForm
        if (this.isEdit) this.$store.dispatch(CUSTOMER_EDIT_ADDRESS, { data })
        else this.$store.dispatch(CUSTOMER_ADD_ADDRESS, { data })
      }
    }
  }
}
</script>
