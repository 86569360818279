<template>
  <v-card color="light_gray" elevation="0">
    <v-form ref="loginForm">
      <v-row>
        <v-col scols="12">
          <div class="text-body-2 unbox_secondary--text">{{ $t(`label.loginMessage`) }}</div>
        </v-col>
        <v-col cols="12" class="py-1">
          <app-form-field v-model.trim="loginForm.email" :label="$t(`label.email`)" :placeholder="$t(`label.enterYourEmail`)" :rules="$validation.emailRules()"></app-form-field>
        </v-col>
        <v-col cols="12" class="py-1">
          <app-form-field
            v-model.trim="loginForm.password"
            :label="$t(`label.password`)"
            type="password"
            :placeholder="$t(`label.enterYourPassword`)"
            :rules="$validation.passwordRules()"
          ></app-form-field>
        </v-col>

        <v-col cols="12" class="py-1">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto">
              <v-checkbox v-model="loginForm.rememberMe" on-icon="$checkIcon" hide-details :ripple="false" class="mt-0 pt-0">
                <template v-slot:label>
                  <span class="text-capitalize text-caption font-weight-bold unbox_black--text">{{ $t(`label.rememberMe`) }}</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-btn @click="openForgetPasswordForm" class="text-capitalize text-caption px-0 font-weight-bold unbox_black--text" min-width="0" height="auto" text :ripple="false">
                {{ $t(`label.forgotPassword`) }}?
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <div class="text-center">
            <v-btn
              class="white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
              @click="submitLogin"
              height="56px"
              max-width="300"
              width="100%"
              color="unbox_black"
              elevation="0"
            >
              {{ $t(`label.signIn`) }}
            </v-btn>
          </div>
          <div class="mt-4 text-center">
            <v-btn
              class="black--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
              @click="openFirstTimeLoginForm"
              height="56px"
              max-width="300"
              width="100%"
              elevation="0"
            >
              {{ $t(`label.firstTimeLogin`) }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import aes256 from 'aes256'
import { CUSTOMER_LOGIN } from '@/store/customer.module'
import { sharedHelper, storageHelper } from '@/utils'
import { KEY } from '@/constants'
export default {
  data: () => ({
    loginForm: {
      email: '',
      password: '',
      rememberMe: false,
      domain: sharedHelper.getHostname(),
      platform: ''
    }
  }),
  mounted() {
    this.checkRememberMeSetting()
  },
  methods: {
    checkRememberMeSetting() {
      const username = storageHelper.getLocalStorage(KEY.UN_KEY)
      const password = storageHelper.getLocalStorage(KEY.PW_KEY)

      if (username != null && password != null) {
        const secretKey = KEY.RMBME_SECRET_KEY

        this.loginForm.email = aes256.decrypt(secretKey, username)
        this.loginForm.password = aes256.decrypt(secretKey, password)
        this.loginForm.rememberMe = true
      }
    },
    submitLogin() {
      if (this.$refs.loginForm.validate()) {
        let data = this.loginForm
        data.domain = sharedHelper.getHostname()
        data.platform = sharedHelper.getPlatform()
        this.$store.dispatch(CUSTOMER_LOGIN, { data })
      }
    },
    openForgetPasswordForm() {
      this.$root.$master.openForgotPasswordFormDialog()
    },
    openFirstTimeLoginForm() {
      this.$root.$master.openFirstTimeLoginFormDialog()
    }
  }
}
</script>
