<template>
  <v-card color="light_gray" elevation="0">
    <v-form ref="registrationForm">
      <v-row>
        <v-col md="6" cols="12">
          <div class="text-body-2 unbox_secondary--text">{{ $t(`label.registrationMessage`) }}</div>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0"></v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field v-model.trim="registrationForm.firstName" :label="$t(`label.firstName`)" :placeholder="$t(`label.firstName`)" :rules="$validation.nameRules()"></app-form-field>
        </v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field v-model.trim="registrationForm.lastName" :label="$t(`label.lastName`)" :placeholder="$t(`label.lastName`)" :rules="$validation.nameRules()"></app-form-field>
        </v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field
            :prefix="registrationForm.mobileNumberCountryCode"
            v-model.trim="registrationForm.mobileNumber"
            :label="$t(`label.mobileNumber`)"
            :placeholder="$t(`label.mobileNumber`)"
            :rules="$validation.mobileNumberRules()"
          ></app-form-field>
        </v-col>
        <v-col md="6" cols="12" class="py-1">
          <app-form-field v-model.trim="registrationForm.email" :label="$t(`label.email`)" :placeholder="$t(`label.email`)" :rules="$validation.emailRules()"></app-form-field>
        </v-col>
        <v-col cols="12">
          <div class="text-center">
            <v-btn
              class="white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
              @click="submitRegistration"
              height="56px"
              max-width="300"
              width="100%"
              color="unbox_black"
              elevation="0"
            >
              {{ $t(`label.signUp`) }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { CUSTOMER_REGISTER } from '@/store/customer.module'
export default {
  data: () => ({
    registrationForm: {
      firstName: '',
      lastName: '',
      mobileNumberCountryCode: '+60',
      mobileNumber: '',
      email: ''
    }
  }),
  watch: {},
  methods: {
    submitRegistration() {
      if (this.$refs.registrationForm.validate()) {
        let data = this.registrationForm
        this.$store.dispatch(CUSTOMER_REGISTER, { data })
      }
    }
  }
}
</script>
