import Vue from 'vue'
import VueRouter from 'vue-router'
import { ROUTE_NAME } from '@/constants/route.constants'
import { SHARED } from '@/constants/constants'
import { sharedHelper } from '@/utils'
Vue.use(VueRouter)

const loginGuard = function (to, from, next) {
  sharedHelper.isAuthenticated() ? next() : next({ name: ROUTE_NAME.HOME })
}

const routes = [
  {
    path: '/',
    name: ROUTE_NAME.HOME,
    component: () => import('@/views/home.vue')
  },
  {
    path: '/app/download',
    name: ROUTE_NAME.APP_DOWNLOAD,
    component: () => import('@/views/app/download.vue')
  },
  /* Process Order */
  {
    path: '/unbox-payment-result',
    name: ROUTE_NAME.PAYMENT_RESULT,
    component: () => import('@/views/payment/paymentProcessResult.vue')
  },
  {
    path: '/unbox-payment-cancel',
    name: ROUTE_NAME.PAYMENT_CANCEL,
    component: () => import('@/views/payment/paymentProcessCancel.vue')
  },
  {
    path: '/unbox-payment-success',
    name: ROUTE_NAME.PAYMENT_SUCCESS,
    component: () => import('@/views/payment/paymentProcessSuccess.vue')
  },
  {
    path: '/unbox-payment-fail',
    name: ROUTE_NAME.PAYMENT_FAIL,
    component: () => import('@/views/payment/paymentProcessFail.vue')
  },

  {
    path: '/payment/success',
    name: ROUTE_NAME.PAYMENT_COMPLETE_SUCCESS,
    component: () => import('@/views/payment/paymentSuccess.vue')
  },
  {
    path: '/payment/cancel',
    name: ROUTE_NAME.PAYMENT_COMPLETE_CANCEL,
    component: () => import('@/views/payment/paymentCancel.vue')
  },
  {
    path: '/payment/fail',
    name: ROUTE_NAME.PAYMENT_COMPLETE_FAIL,
    component: () => import('@/views/payment/paymentFail.vue')
  },
  {
    path: '/order/summary',
    beforeEnter: (to, from, next) => {
      loginGuard(to, from, next)
    },
    meta: { requiredAuthentication: true },
    name: ROUTE_NAME.ORDER_SUMMARY_PAGE,
    component: () => import('@/views/order/orderSummary.vue')
  },
  {
    path: '/create-password',
    name: ROUTE_NAME.CREATE_PASSWORD,
    beforeEnter: (to, from, next) => {
      next({
        name: ROUTE_NAME.HOME,
        params: {
          action: SHARED.CREATE_PASSWORD,
          purpose: SHARED.RESET_PASSWORD_FIRST_TIME_LOGIN,
          key: to.query.token
        }
      })
    }
  },
  {
    path: '/reset-password',
    name: ROUTE_NAME.RESET_PASSWORD,
    beforeEnter: (to, from, next) => {
      next({
        name: ROUTE_NAME.HOME,
        params: {
          action: SHARED.FORGOT_PASSWORD,
          purpose: SHARED.RESET_PASSWORD_FORGOT_PASSWORD,
          key: to.query.token
        }
      })
    }
  },
  {
    path: '/history',
    beforeEnter: (to, from, next) => {
      loginGuard(to, from, next)
    },
    component: () => import('@/views/history/historyLayout.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.HISTORY_HISTORY,
        meta: { requiredAuthentication: true },
        component: () => import('@/views/history/history.vue')
      }
    ]
  },
  {
    path: '/shop',
    component: () => import('@/views/shop/shopLayout.vue'),
    children: [
      {
        path: 'cart',
        name: ROUTE_NAME.SHOP_CART,
        meta: { requiredAuthentication: true },
        component: () => import('@/views/shop/cart.vue'),
        beforeEnter: (to, from, next) => {
          loginGuard(to, from, next)
        }
      },
      {
        path: 'checkout',
        name: ROUTE_NAME.SHOP_CHECKOUT,
        meta: { requiredAuthentication: true },
        component: () => import('@/views/shop/checkout.vue'),
        beforeEnter: (to, from, next) => {
          loginGuard(to, from, next)
        }
      },
      {
        path: 'preorder',
        name: ROUTE_NAME.SHOP_PREORDER_CHECKOUT,
        meta: { requiredAuthentication: true },
        component: () => import('@/views/shop/preOrder.vue'),
        beforeEnter: (to, from, next) => {
          loginGuard(to, from, next)
        }
      },

      {
        path: 'product/:productName',
        name: ROUTE_NAME.SHOP_PRODUCT,
        component: () => import('@/views/shop/shopProduct.vue')
      },
      {
        path: '',
        name: ROUTE_NAME.SHOP,
        component: () => import('@/views/shop/shop.vue')
      }
    ]
  },
  {
    path: '/profile',
    component: () => import('@/views/profile/profileLayout.vue'),
    beforeEnter: (to, from, next) => {
      loginGuard(to, from, next)
    },
    children: [
      {
        path: 'account',
        name: ROUTE_NAME.ACCOUNT,
        meta: { requiredAuthentication: true },
        component: () => import('@/views/profile/account.vue')
      },
      {
        path: 'change-password',
        name: ROUTE_NAME.CHANGE_PASSWORD,
        meta: { requiredAuthentication: true },
        component: () => import('@/views/profile/changePassword.vue')
      },
      {
        path: 'delivery-address',
        name: ROUTE_NAME.DELIVERY_ADDRESSES,
        meta: { requiredAuthentication: true },
        component: () => import('@/views/profile/deliveryAddresses.vue')
      },
      {
        path: 'delete-account',
        name: ROUTE_NAME.DELETE_ACCOUNT,
        meta: { requiredAuthentication: true },
        component: () => import('@/views/profile/deleteAccount.vue')
      }
    ]
  },
  {
    path: '/credit-card',
    component: () => import('@/views/creditCard/creditCardLayout.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.CREDIT_CARD,
        component: () => import('@/views/creditCard/creditCard.vue')
      },
      {
        path: 'apply',
        name: ROUTE_NAME.CREDIT_CARD_APPLY,
        component: () => import('@/views/creditCard/creditCardApplyForm.vue')
      }
    ]
  },
  {
    path: '/personal_financing',
    component: () => import('@/views/personalLoan/personalLoanLayout.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.PERSONAL_LOAN,
        component: () => import('@/views/personalLoan/personalLoan.vue')
      },
      {
        path: 'apply',
        name: ROUTE_NAME.PERSONAL_LOAN_APPLY,
        component: () => import('@/views/personalLoan/personalLoanApplyForm.vue')
      }
    ]
  },
  {
    path: '',
    component: () => import('@/views/helps/helpLayout.vue'),
    children: [
      {
        path: '/privacy-policy',
        alias: '/helps/privacy-policy',
        name: ROUTE_NAME.PRIVACY_POLICY,
        component: () => import('@/views/helps/privacyPolicy.vue')
      },
      {
        path: '/terms-and-conditions',
        alias: '/helps/terms-and-conditions',
        name: ROUTE_NAME.TERMS_AND_CONDITIONS,
        component: () => import('@/views/helps/termsAndConditions.vue')
      },
      {
        path: '/about-us',
        alias: '/helps/about-us',
        name: ROUTE_NAME.ABOUT_US,
        component: () => import('@/views/helps/aboutUs.vue')
      },
      {
        path: '/unbox-care',
        alias: '/helps/production-plan',
        name: ROUTE_NAME.PROTECTION_PLAN,
        component: () => import('@/views/helps/protectionPlan.vue')
      },
      {
        path: '/contact-us',
        alias: '/helps/contact-us',
        name: ROUTE_NAME.CONTACT_US,
        component: () => import('@/views/helps/contactUs.vue')
      },
      {
        path: '/locate-us',
        alias: '/helps/locate-us',
        name: ROUTE_NAME.LOCATE_US,
        component: () => import('@/views/helps/locateUs.vue')
      },
      {
        path: '/refund-policy',
        alias: '/helps/refund-policy',
        name: ROUTE_NAME.REFUND_POLICY,
        component: () => import('@/views/helps/refundPolicy.vue')
      },
      {
        path: '/career',
        alias: '/helps/career',
        name: ROUTE_NAME.CAREER,
        component: () => import('@/views/helps/career.vue')
      }
    ]
  },
  {
    path: '/info',
    component: () => import('@/views/info/infoLayout.vue'),
    children: [
      {
        path: 'pay-later',
        name: ROUTE_NAME.BUY_NOW_PAY_LATER,
        component: () => import('@/views/info/buyNowPayLater.vue')
      },
      // {
      //   path: 'save-now-buy-later',
      //   name: ROUTE_NAME.BANNER_REDIRECT_URL_SNBL_LANDING,
      //   component: () => import('@/views/info/saveNowBuyLater.vue')
      // },
      {
        path: 'trade-in',
        name: ROUTE_NAME.TRADE_IN,
        component: () => import('@/views/info/tradeIn.vue')
      }
    ]
  },
  {
    path: '/classes',
    component: () => import('@/views/classes/classesLayout.vue'),
    children: [
      {
        path: 'register',
        name: ROUTE_NAME.CLASS_REGISTRATION,
        component: () => import('@/views/classes/registerClass.vue')
      }
    ]
  },
  {
    path: '/oppo',
    name: ROUTE_NAME.BANNER_REDIRECT_URL_OPPO,
    redirect: (to) => {
      window.open('https://wa.link/u4sj15', '_blank')
      return '/'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /* TO AUTO BACK TO TOP */
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
