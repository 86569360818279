import { serviceService } from '@/services'
import { sharedHelper } from '@/utils'

const MODULE_NAME = 'service/'

const A_SERVICES = 'services'
const A_SERVICE_BY_GROUP_ID = 'getServiceByGroupid'

export const SERVICE_SERVICES = MODULE_NAME + A_SERVICES
export const SERVICE_BY_GROUP_ID = MODULE_NAME + A_SERVICE_BY_GROUP_ID

const M_SERVICES = 'setServices'
const M_SERVICE_BY_GROUP_ID = 'setServicesByGroupid'

const G_CART_STATUS_UUID_BY_NAME = 'getOrderStatusUuidByName'

export const LOOKUP_CART_STATUS_UUID_BY_NAME = MODULE_NAME + G_CART_STATUS_UUID_BY_NAME

const state = {
  services: [],
  serviceByGroupid: []
}

const getters = {}

const actions = {
  async [A_SERVICES]({ commit, dispatch }) {
    sharedHelper.loading(dispatch)
    await serviceService
      .getServices()
      .then((result) => {
        commit(M_SERVICES, { result })
      })
      .catch((err) => {
        commit(M_SERVICES, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_SERVICE_BY_GROUP_ID]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return serviceService
      .getServiceByGroupid(data.id)
      .then((result) => {
        commit(M_SERVICE_BY_GROUP_ID, { result })
      })
      .catch((err) => {
        commit(M_SERVICE_BY_GROUP_ID, { err })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  }
}

function pushData(item, masterData, insertItem) {
  let object = masterData.find((y) => y.uuid == item.uuid)
  let p = []

  if (insertItem) {
    p.push(object)
  }

  if (object.sub_types.length > 0) {
    object.sub_types.forEach((x) => {
      p.push(masterData.find((y) => y.uuid == x.uuid))
    })
  }

  if (object.sub_types.length > 0) {
    object.sub_types.forEach((x) => {
      p = p.concat(pushData(x, masterData, false))
    })
  }

  return p
}

const mutations = {
  [M_SERVICES](state, { result }) {
    state.services = result.data.map((o) => {
      return {
        data: o
      }
    })
  },
  [M_SERVICE_BY_GROUP_ID](state, { result }) {
    let i = 0
    state.serviceByGroupid = result.data.products.map((o) => {
      return {
        uuid: o.uuid,
        displaySku: o.display_sku,
        name: o.name,
        description: o.description,
        imagePath: o.image_path,
        specification: o.specification,
        engagement: o.engagement,
        minPayment: o.min_payment,
        minPayment: o.max_payment,
        rangePrice: o.range_price,
        sellingPrice: o.selling_price,
        discountedPrice: o.discounted_price,
        contractPeriodType: o.contract_period_type,
        contractPeriod: o.contract_period,
        rangeContractPeriod: o.range_contract_period,
        coverageUrl: o.coverage_url,
        applicationUrl: o.application_url,
        tncUrl: o.tnc_url,
        status: o.status,
        isInstallRequired: o.is_install_required,
        vendorsSku: o.vendors[0].sku,
        vendorsProductCode: o.vendors[0].product_code,
        vendorsVendorCostPrice: o.vendors[0].vendor_cost_price,
        vendorsCommissionRate: o.vendors[0].commission_rate,
        vendorsVendor: {
          vendorUuid: o.vendors[0].vendor.uuid,
          vendorName: o.vendors[0].vendor.name,
          vendorCode: o.vendors[0].vendor.code,
          vendorAddress1: o.vendors[0].vendor.address_1,
          vendorAddress2: o.vendors[0].vendor.address_2,
          vendorAddress3: o.vendors[0].vendor.address_3,
          vendorPostcode: o.vendors[0].vendor.postcode,
          vendorCity: o.vendors[0].vendor.city,
          vendorCountry: o.vendors[0].vendor.country,
          vendorContactPersonName: o.vendors[0].vendor.contact_person_name,
          vendorContactPersonPhoneCode: o.vendors[0].vendor.contact_person_phone_code,
          vendorContactPersonPhoneNo: o.vendors[0].vendor.contact_person_phone_no,
          vendorContactPersonEmail: o.vendors[0].vendor.contact_person_email
        },
        fieldsName: o.fields[0].name,
        fieldsRegex: o.fields[0].regex,
        fieldsIsRequired: o.fields[0].is_required,
        quantity: 0,
        index: i++,
        giftCardMessage: [],
        remarkInstruction: ''
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
