import { API, SHARED } from '@/constants'
import { apiHelper } from '@/utils'

const API_URL = API.BASE_URL.replace('@serviceName', 'payment') + '/v1'

const PAYMENT = {
  PAYMENTS: 'payments',
  LOOKUPS: 'lookups',
  PAYMENT_ACTIONS: 'payment-actions',
  PAYMENT_STATUSES: 'payment-statuses',
  CURRENCIES: 'currencies',
  PAYMENT_TYPES: 'payment-types',
  PAYMENT_GATEWAYS: 'payment-gateways',
  PAYMENT_TRANSACTIONS: 'payment-transactions',
  MERCHANTS: 'merchants'
}

export function makePayment(data) {
  let requestOptions = {
    payment_gateway_uuid: data.paymentMethodId,
    amount: data.paymentAmount,
    tax_amount: 0,
    shipping_amount: 0,
    original_amount: data.paymentAmount,
    currency: data.currency,
    platform: SHARED.PLATFORM,
    is_split_payment: false,
    reference_id: data.orderReferenceId,
    customer_name: data.customerName,
    customer_email: data.customerEmail,
    customer_phone_number: data.customerPhoneNumber
  }
  return apiHelper(API_URL, true).post(`/${PAYMENT.PAYMENTS}`, requestOptions)
}

export function getPaymentGateways(data) {
  let requestOptions = {
    params: {
      page: 1,
      size: 50,
      is_online: 1,
      supported_platform: SHARED.PLATFORM,
      currency: data.currency
    }
  }
  return apiHelper(API_URL, true).get(`/${PAYMENT.PAYMENT_GATEWAYS}`, requestOptions)
}

export function getPaymentTransactionByMerchantTransactionId(data) {
  return apiHelper(API_URL, true).get(`/${PAYMENT.PAYMENT_TRANSACTIONS}/${PAYMENT.MERCHANTS}/${data.id}`)
}

/* LOOKUP */
export function getPaymentTypes() {
  return apiHelper(API_URL, true).get(`/${PAYMENT.LOOKUPS}/${PAYMENT.PAYMENT_TYPES}`)
}
