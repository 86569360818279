//define module name
const MODULE_NAME = 'shared/'

//define actions name
const A_LOADING = 'loading'
const A_UNLOADING = 'unloading'
const A_SHOW_DIALOG = 'showDialog'
const A_CLOSE_DIALOG = 'closeDialog'

//define dispatch action
export const SHARED_LOADING = MODULE_NAME + A_LOADING
export const SHARED_UNLOADING = MODULE_NAME + A_UNLOADING
export const SHARED_SHOW_DIALOG = MODULE_NAME + A_SHOW_DIALOG
export const SHARED_CLOSE_DIALOG = MODULE_NAME + A_CLOSE_DIALOG

//define mutations name
const M_LOADING = 'setLoading'
const M_UNLOADING = 'setUnloading'
const M_SHOW_DIALOG = 'setDialog'
const M_CLOSE_DIALOG = 'clearDialog'

//init app state
const state = {
  loadingIndex: 0,
  messageDialogDisplay: false,
  messageDialog: {
    messageTitle: '',
    messages: [],
    buttons: [],
    type: '',
    messageErrorCode: '0'
  }
}

const getters = {}

const actions = {
  [A_LOADING]({ commit }) {
    commit(M_LOADING)
  },
  [A_UNLOADING]({ commit }) {
    commit(M_UNLOADING)
  },
  [A_SHOW_DIALOG]({ commit }, messageDialog) {
    commit(M_SHOW_DIALOG, messageDialog)
  },
  [A_CLOSE_DIALOG]({ commit }) {
    commit(M_CLOSE_DIALOG)
  }
}

const mutations = {
  [M_LOADING](state) {
    state.loadingIndex += 1
  },
  [M_UNLOADING](state) {
    state.loadingIndex -= 1
  },
  [M_SHOW_DIALOG](state, messageDialog) {
    state.messageDialog.messageTitle = messageDialog.messageTitle
    state.messageDialog.messages = messageDialog.messages
    state.messageDialog.buttons = messageDialog.buttons
    state.messageDialog.type = messageDialog.type
    state.messageDialog.messageErrorCode = messageDialog.messageErrorCode
    state.messageDialogDisplay = true
  },
  [M_CLOSE_DIALOG](state) {
    state.messageDialogDisplay = false
    state.messageDialog.messageTitle = ''
    state.messageDialog.messages = []
    state.messageDialog.buttons = []
    state.messageDialog.type = ''
    state.messageDialog.messageErrorCode = '0'
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
