<template>
  <v-overlay opacity="0.7" z-index="300" :value="loadingIndex > 0">
    <v-progress-circular indeterminate size="100" width="10" color="unbox_primary"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'appLoadingProgress',
  data: () => ({ loading: false }),
  computed: {
    loadingIndex() {
      return this.$store.state.shared.loadingIndex
    }
  },
  methods: {}
}
</script>
