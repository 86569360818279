import Vue from 'vue'
import Vuex from 'vuex'

import customer from './customer.module'
import lookup from './lookup.module'
import order from './order.module'
import payment from './payment.module'
import product from './product.module'
import shared from './shared.module'
import stock from './stock.module'
import loan from './loan.module'
import service from './service.module'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: { customer, lookup, order, payment, product, shared, stock, loan, service }
})
