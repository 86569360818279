<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import { LOOKUPS_PRODUCT_BRANDS, LOOKUPS_PRODUCT_TYPES, LOOKUPS_PRODUCT_CATEGORIES, LOOKUPS_PRODUCT_GROUPS } from '@/store/lookup.module'
import { SHARED, CACHE_KEY } from '@/constants'
import { localeHelper } from '@/utils'
export default {
  name: 'App',
  metaInfo: {
    title: `${localeHelper.getMessage('meta.default_title')}`,
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: `${localeHelper.getMessage('meta.default_description')}` },
      { name: 'keyword', content: `${localeHelper.getMessage('meta.default_keyword')}` }
    ]
  },
  mounted() {
    this.getProductTypesLookup()
    this.getProductBrandsLookup()
    this.getProductCategoriesLookup()
    this.initilizePageLanguage()
  },
  computed: {
    layout() {
      return this.$route.meta.layout || SHARED.LAYOUT
    }
  },
  data: () => ({}),
  methods: {
    getProductBrandsLookup() {
      this.$store.dispatch(LOOKUPS_PRODUCT_BRANDS)
    },
    getProductTypesLookup() {
      this.$store.dispatch(LOOKUPS_PRODUCT_TYPES)
    },
    getProductCategoriesLookup() {
      this.$store.dispatch(LOOKUPS_PRODUCT_CATEGORIES)
    },
    initilizePageLanguage() {}
  }
}
</script>
