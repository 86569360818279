/* 
Custom plugin to make validation rules accessible in VUE files by:
this.$validations.ruleName (omit 'this' if using inside <template>)
*/
import { sharedHelper } from '@/utils'

export default {
  install(Vue, options) {
    Vue.prototype.$shared = { ...sharedHelper }
  }
}
