<template>
  <v-card color="light_gray" elevation="0">
    <v-form ref="forgotPasswordForm" @submit.prevent="submitForgotPassword">
      <v-row>
        <v-col scols="12">
          <div class="text-body-2 unbox_secondary--text">{{ $t(`label.forgotPasswordMessage`) }}</div>
        </v-col>
        <v-col cols="12" class="py-1">
          <app-form-field v-model.trim="forgotPasswordForm.email" :label="$t(`label.email`)" :placeholder="$t(`label.email`)" :rules="$validation.emailRules()"></app-form-field>
        </v-col>

        <v-col cols="12">
          <div class="text-center">
            <v-btn
              class="full-width white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
              type="submit"
              height="56px"
              max-width="300px"
              color="unbox_black"
              elevation="0"
            >
              {{ $t(`label.submit`) }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { SHARED, MEDIUM } from '@/constants'
import { CUSTOMER_GET_RESET_PASSWORD_LINK } from '@/store/customer.module'

export default {
  data: () => ({
    forgotPasswordForm: {
      email: '',
      purpose: SHARED.RESET_PASSWORD_FIRST_TIME_LOGIN,
      medium: MEDIUM.EMAIL
    }
  }),
  watch: {},
  methods: {
    submitForgotPassword() {
      if (this.$refs.forgotPasswordForm.validate()) {
        let data = this.forgotPasswordForm
        this.$store.dispatch(CUSTOMER_GET_RESET_PASSWORD_LINK, { data })
      }
    }
  }
}
</script>
