import axios from 'axios'
import { SESSION, SHARED } from '@/constants'
import { storageHelper } from '@/utils'

export function apiHelper(baseURL, appendAuthHeader = true, isBackgroundCall = false) {
  let authHeader = {}
  const userToken = storageHelper.getUserToken()

  if (userToken && appendAuthHeader) {
    authHeader.Authorization = `Bearer ${userToken}`
  }

  authHeader.platform = SHARED.PLATFORM
  authHeader['api-key'] = SHARED.API_KEY

  const responseHandler = (response) => {
    if (!isBackgroundCall) {
      localStorage.setItem(SESSION.LAST_ACTIVITY_TIME, JSON.stringify(new Date().getTime()))
    }
    return response.data
  }

  const errorHandler = (error) => {
    if (!error.response) {
      if (error.message === 'Network Error') {
        error.response.status = 503
        error.response.data = {
          success: false,
          code: '0.96',
          error: 'Network Error'
        }
      } else {
        error.response.data = {
          success: error.response.data.success,
          code: error.response.data.code,
          error: error.response.data.error
        }
      }
    }

    throw error.response.data
  }

  let apiInstance = axios.create({
    baseURL,
    headers: authHeader
  })

  apiInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  )

  return apiInstance
}
